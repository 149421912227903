/* eslint-disable no-shadow, no-param-reassign */

const state = {
  reAuthorizationTime: 0,
  reAuthorizationTimer: null,
};

const getters = {
  reAuthorizationTime: (state) => state.reAuthorizationTime,
  reAuthorizationTimer: (state) => state.reAuthorizationTimer,
};

const actions = {

  startReAuthorizationTimerAction: ({ state, commit, dispatch }, value) => {
    dispatch("stopReAuthorizationTimerAction");
    commit("setReAuthorizationTime", value);

    const timer = setInterval(() => {
      if (!state.reAuthorizationTime) {
        dispatch("stopReAuthorizationTimerAction");
        return;
      }

      commit("setReAuthorizationTime", state.reAuthorizationTime - 1);
    }, 1000);

    commit("setReAuthorizationTimer", timer);
  },

  stopReAuthorizationTimerAction: ({ state }) => {
    clearInterval(state.reAuthorizationTimer);
  },

};

const mutations = {
  setReAuthorizationTime: (state, value) => {
    state.reAuthorizationTime = value;
  },

  setReAuthorizationTimer: (state, value) => {
    state.reAuthorizationTimer = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
