// --- INJECTED ---
import { getI18n } from '@/core/i18n';

const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('Телеметрия'),
    route: { name: 'equipment$equipment' },
  },
  {
    label: '',
    route: { name: 'equipment$detail' }, // 1
  },
];

export default [
  {
    path: '/equipment',
    name: 'equipment$equipment',
    component: () => import('@/apps/equipment/views/Equipment'),
    meta: {
      breadCrumb: [breadCrumb[0]],
      title: i18n.t('Телеметрия'),
    },
  },
  {
    path: '/equipment/:id',
    name: 'equipment$detail',
    component: () => import('@/apps/equipment/views/Detail/Detail'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
      title: i18n.t('Страница телеметрии'),
    },
  },
];
