// --- INJECTED ---
import {getI18n} from '@/core/i18n';

const i18n = getI18n();
// --- /INJECTED ---

import {companyAPI} from '@/core/api/company';

const state = {
  menuTableColumns: [
    {
      name: 'id',
      label: 'ID',
      enableSorting: false,
      removable: false,
    },
    {
      name: 'title',
      label: i18n.t('Название меню'),
      enableSorting: false,
      removable: false,
    },
    {
      name: 'base_catalog',
      label: i18n.t('Базовый каталог'),
      disableClickEvent: true,
    },
    {
      name: 'stores_count',
      label: i18n.t('Торговые точки'),
      class: 'u-align-center',
      headerClass: 'u-align-center',
      disableClickEvent: true,
      enableSorting: true,
    },
  ],

  menuRequestFields: {
    id: 'id',
    title: 'title',
    stores: 'stores',
    items: 'items',
    display_mode: 'display_mode',
    catalog_id: 'catalog_id',
    stores_count: 'stores_count'
  },

  menuFilterOptions: [
    {
      name: 'catalog_id',
      label: i18n.t('Каталоги'),
      type: 'multiselect',
      inputPlaceholder: i18n.t('Название каталога...'),
      remoteMethod: async (params) => {
        const fields = {
          barcode_prefix_2: 'barcode_prefix_2',
          company_id: 'company_id',
          currency_code: 'currency_code',
          id: 'id',
          item_type: 'item_type',
          name: 'name',
        };

        const filters = {
          '!item_type': ['coffee'],
        };
        return await companyAPI.getCatalogList({ fields, filters, ...params });
      },
    },
  ],

  menuTablePagination: {
    totalItems: null,
    totalPages: 1,
    page: 1,
    limit: 10,
  },

  menuTableFilters: {
    stores: [],
    catalog_id: [],
    items: []
  },

  menuTableSorting: {
    stores_count: ''
  },

  storeMenuTableColumns: [
    {
      name: 'radio_button',
      label: ' ',
      disableClickEvent: true,
    },
    {
      name: 'name',
      label: i18n.t('ID или название меню'),
      enableSorting: false,
      headerClass: 'u-align-center',
      removable: false,
    },

    {
      name: 'base_catalog',
      label: i18n.t('Базовый каталог'),
      disableClickEvent: false,
    },
    {
      name: 'stores_count',
      label: i18n.t('Торговые точки'),
      class: 'u-align-center',
      headerClass: 'u-align-center',
      disableClickEvent: true,
      enableSorting: false,
    },
  ],
  tradePointsTableColumns: [
    {
      name: 'checkbox',
      disableClickEvent: true,
    },
    {
      name: 'name',
      label: i18n.t('ID и название торговой точки'),
      disableClickEvent: true,
    },
    {
      name: 'extended_type',
      label: i18n.t('Тип'),
    },
    {
      name: 'address',
      label: i18n.t('Адрес'),
    },
    {
      name: 'flag_is_test',
      label: i18n.t('Активна'),
      class: 'u-align-center',
    },
  ],
  storeItemsTableColumns: [
    {
      name: 'id',
      label: 'ID',
      removable: false,
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'photo_link_100x100',
      label: i18n.t('Фото'),
      class: 'u-pa-8 u-maxh--60 u-maxw--60',
      headerClass: 'u-align-center',
      removable: true,
    },
    {
      name: 'name',
      label: i18n.t('Название'),
      removable: false,
    },
    {
      name: 'unit',
      label: i18n.t('Измерение'),
      class: 'u-align-center',
      headerClass: 'u-align-center',
      removable: true,
    },
    {
      name: 'price',
      label: i18n.t('Цена'),
      class: 'u-align-center',
      headerClass: 'u-align-center',
      enableSorting: true,
      removable: false,
    },
    {
      name: 'status',
      label: i18n.t('Активен'),
      class: 'u-align-center',
      headerClass: 'u-align-center',
      enableSorting: true,
      removable: true,
    },
  ],

  storeItemsTablePagination: {
    totalItems: null,
    totalPages: 1,
    page: 1,
    limit: 10,
  },

  storeItemsTableFilters: {
  },

  storeItemsTableFields: {
    id: 'id',
    photo_link_100x100: 'photo_link',
    name: 'name',
    unit: 'unit',
    price: 'price',
    status: 'status',
  },

  storeItemsTableSorting: {
    price: '',
    status: '',
  },

  itemsTableColumns: [
    {
      name: 'checkbox',
      disableClickEvent: true,
    },
    {
      name: 'id',
      label: 'ID',
      removable: false,
    },
    {
      name: 'photo_link_100x100',
      label: i18n.t('Фото'),
      class: 'u-pa-8 u-maxh--64 u-maxw--64',
      removable: true,
    },
    {
      name: 'name',
      label: i18n.t('Название'),
      removable: false,
    },
    {
      name: 'unit',
      label: i18n.t('Измерение'),
      removable: true,
    },
    {
      name: 'price',
      label: i18n.t('Цена'),
      enableSorting: true,
      removable: false,
    },

    {
      name: 'quantity_calculated',
      label: i18n.t('Кол-во'),
      class: 'u-align-center',
      headerClass: 'u-align-center',
      enableSorting: true,
      removable: true,
    },
    {
      name: 'status',
      label: i18n.t('Активен'),
      class: 'u-align-center',
      headerClass: 'u-align-center',
      enableSorting: true,
      removable: true,
    },
  ],

  itemsTableFields: {
    id: 'id',
    photo_link_100x100: 'photo_link',
    name: 'name',
    unit: 'unit',
    price: 'price',
    quantity_calculated: 'quantity_calculated',
    status: 'status',
  },

  itemsTablePagination: {
    totalItems: null,
    totalPages: 1,
    page: 1,
    limit: 10,
  },

  itemsTableSorting: {
    price: '',
    quantity_calculated: '',
    status: '',
  },

  itemsTableFilters: {
    catalog_id: [],
    '!catalog.item_type': ['coffee'],
  },

  employeePreferencesKey: ['itemsHiddenFields', 'items_hidden_fields'],

  catalogsTableColumns: [
    {
      name: 'checkbox',
      disableClickEvent: true,
    },
    {
      name: 'name',
      label: i18n.t('ID и название каталога'),
      disableClickEvent: true,
    },
  ],
};

const getters = {
  menuTableColumns: state => state.menuTableColumns,
  menuTablePagination: state => state.menuTablePagination,
  menuTableFilters: state => state.menuTableFilters,
  menuFilterOptions: state => state.menuFilterOptions,
  menuRequestFields: state => state.menuRequestFields,
  menuTableSorting: state => state.menuTableSorting,
  tradePointsTableColumns: state => state.tradePointsTableColumns,
  itemsTableColumns: state => state.itemsTableColumns,
  itemsTableFields: state => state.itemsTableFields,
  itemsTablePagination: state => state.itemsTablePagination,
  itemsTableSorting: state => state.itemsTableSorting,
  itemsTableFilters: state => state.itemsTableFilters,
  catalogsTableColumns: state => state.catalogsTableColumns,
  storeItemsTableColumns: state => state.storeItemsTableColumns,
  storeItemsTablePagination: state => state.storeItemsTablePagination,
  storeItemsTableFilters: state => state.storeItemsTableFilters,
  storeItemsTableFields: state => state.storeItemsTableFields,
  storeItemsTableSorting: state => state.storeItemsTableSorting,
  storeMenuTableColumns: state => state.storeMenuTableColumns,
  employeePreferencesKey: state => state.employeePreferencesKey,
};

const mutations = {

};

const actions = {

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
