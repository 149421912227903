import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';

function getTitle(title) {
  if (!title) return 'Briskly Business';
  return 'Briskly Business / ' + title;
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from) {
    if (to.name === from.name) return;
    return { left: 0, top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  if (nearestWithTitle && nearestWithTitle.meta && nearestWithTitle.meta.title) {
    document.title = getTitle(nearestWithTitle.meta.title);
  }
  next();
});

export default router;
