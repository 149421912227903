<template>
  <div class="chat" ref="chat">
    <div class="u-flex u-flex--col">
      <div class="response">
        <div class="response__container">
          <Icon name="dot" class="admin-icon" />
          <h5 class="response__person">{{ 'Briskly' }}</h5>
        </div>

        <p class="response__text">{{ chat_message.text }}</p>
      </div>
    </div>
    <div v-for="(group, key) in messages" :key="key" class="u-flex u-flex--col">
      <Chip :text="formatChipToDayMonth(group.date)" class="chip" />
      <div v-for="(message, key) in group.items" :key="key">
        <div class="u-flex u-flex--col" v-if="message.sender.alias === 'administrator'">
          <div class="response">
            <div class="u-flex u-ai-center">
              <Icon name="dot" class="admin-icon" />
              <h5 class="response__person">{{ 'Briskly' }}</h5>
            </div>
            <p class="response__text">{{ message.text }}</p>
            <p class="response__date">{{ formatDateToTime(message.created_at) }}</p>
          </div>
        </div>
        <div class="u-flex u-flex--col" v-else>
          <div class="request">
            <p class="request__text">{{ message.text }}</p>

            <div class="u-flex u-ai-center request__container">
              <Icon name="icon_send" class="request__isread" v-if="!message.is_read" />
              <Icon name="icon_isread" class="request__isread" v-else />

              <p class="request__date">{{ formatDateToTime(message.created_at) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="input-container">
    <BaseInput
      class="chat-input"
      v-model="newChatMessage"
      innerIconDirection="left"
      innerIcon="icon_send-message"
      @keydown.enter="onKeydownSend"
    >
      <template #icon-inner>
        <Icon name="icon_send-message" class="icon-send" @click="onSendMessage" @keydown.enter="onKeydownSend" />
      </template>
    </BaseInput>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Icon, BaseInput } from '@brskl/ui-lib';
import { format } from 'date-fns';
import Chip from '@/apps/reports/component/Chip.vue';

export default defineComponent({
  props: {
    messages: {
      type: Object,
    },
  },

  emits: ['sendMessage'],
  //@ts-ignore
  components: { Icon, BaseInput, Chip },

  data() {
    return {
      txt: 'txt',
      date: '20-04-2021',
      person: 'meme memev',
      newChatMessage: '',

      chat_message: {
        chat: {
          administrator_id: 48,
          company_id: 0,
          customer: null,
          employee: null,
          funnel: null,
          id: -1,
          last_comment: null,
          last_message: null,
          name: '',
          status: 1,
        },
        chat_id: 2633,
        created_at: this.formatDateToTime(new Date().toISOString()),
        files: [],
        id: -1,
        is_read: false,
        order: [],
        order_id: 0,
        reply_to_id: 0,
        reply_to_message: [],
        sender: {
          alias: 'administrator',
          id: 48,
          name: 'Briskly',
        },
        text: this.$t('support_service_greeting'),
        type: 0,
        scrollTimeout: null,
      },
    };
  },

  methods: {
    formatDateToTime(date: string) {
      const _date = date.split(' ').join('T');
      return format(new Date(_date), 'HH:mm');
    },

    formatChipToDayMonth(date) {
      const _date = date.split(' ').join('T');
      const month = 'MONTH_' + format(new Date(_date), 'MM');
      const day = format(new Date(_date), 'dd');
      return day + ` ${this.$t(month)}`;
    },

    onSendMessage() {
      this.$emit('sendMessage', this.newChatMessage);
      this.newChatMessage = '';
    },

    onKeydownSend(ev) {
      if (ev.shiftKey) return;

      ev.preventDefault();
      this.onSendMessage();
    },

    scrollToBottom(smooth = true) {
      this.$nextTick(() => {
        this.scrollTimeout = setTimeout(() => {
          this.$refs.chat.scrollTo({
            top: this.$refs.chat.scrollHeight,
            behavior: smooth ? 'smooth' : undefined,
          });
        }, 0);
      });
    },
  },

  watch: {
    messages(newMessage, oldMessage) {
      if (!!newMessage) {
        this.scrollToBottom();
      }
    },
  },

  mounted() {
    this.scrollToBottom();
  },

  unmounted() {
    clearTimeout(this.scrollTimeout);
  },
});
</script>

<style lang="scss" scoped>
$height-chat-input: 80px;


.chat {
  display: flex;
  flex-direction: column;
  width: 100%;
  // overflow-y: auto;
  background: #fff;
  padding-bottom: $height-chat-input;
}

.response {
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  min-height: 72px;
  min-width: 243px;
  max-width: 600px;
  background: rgba(196, 211, 234, 0.5);
  border-radius: 8px;
  margin: 8px 32px;
  padding: 12px;
  word-break: break-all;

  &__person {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #515c72;
    margin: 0 0 0 6px;
  }

  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #191b23;
    align-self: flex-start;
  }

  &__date {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6a7b95;
    align-self: flex-end;
  }

  &__container {
    display: flex;
    align-items: center;
    margin: 0 0 4px 0;
  }
}

.request {
  display: flex;
  flex-direction: column;
  align-self: flex-end;

  min-height: 52px;
  min-width: 243px;
  max-width: 600px;

  background: #e9ebf3;
  border-radius: 8px;
  padding: 12px;
  margin: 8px 32px;
  word-break: break-all;

  &__person {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #515c72;
    align-self: flex-start;
  }

  &__container {
    align-self: flex-end;
  }

  &__isread {
    width: 14px !important;
    height: 14px !important;
    margin: 0 4px 0 0;
  }

  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #191b23;
    align-self: flex-start;
  }

  &__date {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6a7b95;
  }
}

.icon-send {
  width: 24px !important;
  height: 24px !important;
}

.chat-input {
  width: 100%;

  &:deep(.base-input__icon) {
    display: flex;
    align-items: center;
  }
}

.input-container {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  background: #fff;
  margin: 30px;
  max-height: $height-chat-input;
}

.chip {
  width: 100px;
  white-space: nowrap;
  align-self: center;
  cursor: default;
  user-select: none;
  margin: 31px 0;
}

.admin-icon {
  width: 24px !important;
  height: 24px !important;
  fill: #22c951;
}
</style>
