import { companyAPI } from '@/core/api/company';

//Азбука вкуса
const AV_ID = 297;

const state = {
  company: {},
  companies: [],
  parties: [],
};

const getters = {
  company: (state) => state.company,
  companies: (state) => state.companies,
  parties: (state) => state.parties,

  isRussian: (state) => {
    const countryCode = state.company.country_code || '';
    return countryCode.toLowerCase() === 'ru';
  },
};

const actions = {
  init: async ({ commit }) => {
    try {
      const company = await companyAPI.getSelfCompany();
      const companies = await companyAPI.getAvailableCompanies();
      const parties = await companyAPI.getExchangePartyListOld();
      commit('setCompany', company);
      commit('setCompanies', companies);
      commit('setParties', parties.items);
      return true;
    } catch (error) {
      console.error(error);
    }
  },

  updateSelfCompany: async ({ commit }) => {
    const company = await companyAPI.updateSelfCompany();
    commit('setCompany', company);
  },

  updateCompany: async ({ commit }, company) => {
    const updatedCompany = await companyAPI.updateSelfCompany(company);
    commit('setCompany', updatedCompany);
  },

  bindEmployeeToCompany: async ({ commit }, company_id) => {
    try {
      await companyAPI.bindEmployeeToCompany(company_id);
    } catch (error) {}
  },
};

const mutations = {
  setCompany: (state, value) => {
    state.company = value;
  },

  setCompanies: (state, value) => {
    state.companies = value;
  },

  setParties: (state, value) => {
    state.parties = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
