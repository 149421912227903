import { bus } from '@brskl/core';

interface TChannelConstructor {
  url?: string;
  endpoint: any;
}

class ChannelHandler {
  url;
  endpoint;
  channel: WebSocket;

  constructor({ url, endpoint }: TChannelConstructor) {
    this.url = url || '/dashboard/profile/get-channel/';
    this.endpoint = endpoint;
  }

  handler = (ev) => {
    // Это обработчик сообщений сокета
    try {
      const message = JSON.parse(ev.data);

      if (message && message.data && 'order' in message.data) {
        return bus.emit('core$onlineCarts/newData', message.data);
      }

      if (message && message.data && 'action' in message.data) {
        return bus.emit('core$onlineCarts/newMessage', message.data);
      }

      if (message && message.data && 'chat_message' in message.data) {
        return bus.emit('core$chat/newChatMessage', message.data);
      }

      //console.log('socket message:', message);
    } catch (error) {
      console.error(error);
    }
  };
}

export default ChannelHandler;
