<template>
  <Preloader v-if="loading" />
  <router-view></router-view>
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex';
import Preloader from '@/core/views/Preloader.vue';

export default {
  components: {
    Preloader,
  },

  computed: {
    ...mapGetters({
      loading: 'core$main/loading',
    }),
  },

  created() {
    this.globalInit({ currentRoute: this.$route.fullPath });
  },

  methods: {
    ...mapActions({
      globalInit: 'core$main/globalInit',
    }),
  },
};
</script>
