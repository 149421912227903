<template>
  <div :class="['custom-header-container', `d-${displayMode}`]">
    <Button
      v-if="displayMode === 'mobile'"
      width="36px"
      padding="0"
      color="--color-text"
      @click="$emit('menuClickHadnler')"
      class="header-icon-btn"
    >
      <Icon name="menu" class="header-icon" />
    </Button>
    <Breadcrumb class="header-breadcrumb" />
    <div class="header-filters-wrapper">
      <div class="header-filters" id="teleport-header"> </div>
    </div>
  </div>
</template>

<script>
import { Breadcrumb, Icon, Button } from '@brskl/ui-lib';

export default {
  emits: ['menuClickHadnler'],
  components: { Breadcrumb, Button, Icon },
  props: {
    displayMode: String,
  },
  computed: {
    isMobile() {
      return this.displayMode === 'mobile';
    },
  },
};
</script>

<style lang="scss" scoped src="./Header.scss"></style>
