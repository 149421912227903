import {TExternalCompanyInfo} from "@/apps/service_center/types/contractors";

type TState = {
  selectedCompany: TExternalCompanyInfo | null,
}

const state: TState = {
  selectedCompany: null,
};

const getters = {
  selectedCompany: (state: TState): TExternalCompanyInfo | null => state.selectedCompany,
};

const actions = {
  selectCompany: ({ commit }, company: TExternalCompanyInfo) => {
    commit('_selectCompany', company);
  },
  clearSelectedCompany: ({ commit }) => {
    commit('_clearSelectedCompany');
  },
};

const mutations = {
  _selectCompany: (state: TState, payload: TExternalCompanyInfo) => {
    state.selectedCompany = payload;
  },
  _clearSelectedCompany: (state: TState) => {
    state.selectedCompany = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
