<template>
  <div class="preloader">
    <Lottie :height="100" :width="100" :options="animationData" />
  </div>
</template>

<script>
import { Lottie } from '@brskl/ui-lib';
import loaderAnimation from '@/assets/lottie/loader.json';

export default {
  components: {
    Lottie,
  },
  data() {
    return {
      animationData: {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/core/styles/mixins';

@keyframes spin {
  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.icon {
  animation: 2s ease spin infinite;
  height: rem(50px);
  width: rem(50px);
}
</style>
