// --- INJECTED ---
import {getI18n} from '@/core/i18n';

const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('Сервисный центр'),
  },
  {
    label: i18n.t('Оборудование'),
    route: {name: 'service-devices$list'},
  },
  {
    label: i18n.t('Отправка'),
    route: {name: 'service-devices$sending'},
  },
  {
    label: i18n.t('Приемка на баланс'),
    route: {name: 'service-devices$receiving'},
  },
  {
    label: i18n.t('Оформление возврата'),
    route: {name: 'service-devices$receiving'},
  },
  {
    label: i18n.t('Детали отправки'),
    route: {name: 'service-devices$demand'},
  },
];

export default [
  {
    path: '/service-center/devices',
    name: 'service-devices$list',
    component: () => import('@/apps/service_center/devices/views/List/List.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
    },
  },
  {
    path: '/service-center/devices/sending',
    name: 'service-devices$sending',
    component: () => import('@/apps/service_center/devices/views/Sending/Sending.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[2]],
    },
  },
  {
    path: '/service-center/devices/receiving',
    name: 'service-devices$receiving',
    component: () => import('@/apps/service_center/devices/views/Receiving/Receiving.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[3]],
    },
  },
  {
    path: '/service-center/devices/refunding',
    name: 'service-devices$refunding',
    component: () => import('@/apps/service_center/devices/views/Refunding/Refunding.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[4]],
    },
  },
  {
    path: '/service-center/devices/demand',
    name: 'service-devices$demand',
    component: () => import('@/apps/service_center/devices/views/Detail/Detail.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[5]],
    },
  },
];
