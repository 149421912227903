// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
    {
        label: i18n.t('Товары'),
        route: { name: 'items$items' },
    },
    {
        label: '',
        route: { name: 'items$item' },
    },
    {
        label: i18n.t('Печать стикера и ценника'),
        route: { name: 'items$item-sticker' },
    },
    {
        label: i18n.t('Новый товар'),
        route: { name: 'items$item-create' },
    },
];

export default [
    {
        path: '/items',
        name: 'items$items',
        component: () => import('@/apps/items/views/Items'),
        meta: {
            breadCrumb: [breadCrumb[0]],
            title: i18n.t('Товары'),
        },
    },
    {
        path: '/items/:id',
        name: 'items$item', //if you will change route name at item detail and change routes too
        component: () => import('@/apps/items/views/ItemCreate/ItemCreate'),
        meta: {
            breadCrumb: [breadCrumb[0], breadCrumb[1]],
            title: i18n.t('Страница товара'),
        },
    },
    {
        path: '/items/coffee-machine/:id/:machineType/:isNew/:catalogID/:tradepointID',
        name: 'items$item-coffee-machine', //memes for the memes, until we can handle loss of params at page reload
        component: () => import('@/apps/items/views/ItemCoffeeMachine/ItemCoffeeMachine'),
        meta: {
            breadCrumb: [breadCrumb[0], breadCrumb[1]],
            title: i18n.t('Страница товара'),
        },
    },

    {
        path: '/items/create-coffee-machine-item/:machineType/:isNew/:catalogID/:tradepointID',
        name: 'items$item-create-coffee-machine', //memes for the memes, until we can handle loss of params at page reload
        component: () => import('@/apps/items/views/ItemCoffeeMachine/ItemCoffeeMachine'),
        meta: {
            breadCrumb: [breadCrumb[0], breadCrumb[1]],
            title: i18n.t('Товары'),
        },
    },
    {
        path: '/items/create',
        name: 'items$item-create',
        component: () => import('@/apps/items/views/ItemCreate/ItemCreate'),
        meta: {
            breadCrumb: [breadCrumb[0], breadCrumb[3]],
            title: i18n.t('Товары'),
        },
    },
    {
        path: '/items/sticker-print/',
        name: 'items$item-sticker',
        component: () => import('@/apps/items/views/ItemSticker/ItemSticker'),
        meta: {
            breadCrumb: [breadCrumb[0], breadCrumb[2]],
            title: i18n.t('Печать стикера'),
        },
    },
];
