// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('Меню'),
    route: { name: 'menus$menus' },
  },
  {
    label: i18n.t('Новое Меню'),
    route: { name: 'menus$menus-create' },
  },
  {
    label: '',
    route: { name: 'menus$menu' },
  }
];

export default [
  {
    path: '/menus',
    name: 'menus$menus',
    component: () => import('@/apps/menus/views/Menus.vue'),
    meta: {
      breadCrumb: [breadCrumb[0]],
      title: i18n.t('Меню'),
    },
  },
  {
    path: '/menus/create',
    name: 'menus$menus-create',
    component: () => import('@/apps/menus/views/MenuCreate/MenuCreate.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
      title: i18n.t('Меню'),
    },
  },
  {
    path: '/menus/:id',
    name: 'menus$menu',
    component: () => import('@/apps/menus/views/MenuCreate/MenuCreate.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[2]],
      title: i18n.t('Страница меню'),
    },
  },
];
