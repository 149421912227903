import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz';
import { isValid } from 'date-fns';

const ISO_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.sss'Z'";

const formatIso = (iso) => {
  const [date, time] = iso.split('T');
  const stringDate = `${date} ${time}`.replace(/\..*/, '');

  return format(new Date(stringDate), 'yyyy-MM-dd HH:mm:ss');
};

const getBrowserTimeZoneOffset = (defaultTimeZone = 'Europe/Moscow') => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone || defaultTimeZone;
};

const getTimeZone = () => {
  const selectedTimeZoneOffset = localStorage.getItem('timeZoneOffset');
  const browserTimeZoneOffset = getBrowserTimeZoneOffset();

  return selectedTimeZoneOffset === null ? browserTimeZoneOffset : selectedTimeZoneOffset;
};

const localeToUTC = (_date) => {
  const date = new Date(_date);
  if (!isValid(date)) return _date;

  const timeZone = getTimeZone();
  const utc = zonedTimeToUtc(date, timeZone);

  return formatIso(utc.toISOString());
};

const utcToLocale = (_date) => {
  const date = new Date(_date);
  if (!isValid(date)) return _date;

  const isoDate = format(date, ISO_FORMAT);
  const timeZone = getTimeZone();
  const locale = utcToZonedTime(isoDate, timeZone);

  return format(locale, 'yyyy-MM-dd HH:mm:ss');
};

export { localeToUTC, utcToLocale, getTimeZone };
