import { companyAPI } from '@/core/api/company';
import { NoticeEnumTypes } from '@/core/data/Notice-enum';

const state = {
  locale: '',
  company: null,
  companies: [],
};

const getters = {
  locale: (state) => state.locale,
  company: (state) => state.company,
  companies: (state) => state.companies,
};

const actions = {
  setlocale: ({ commit }, locale) => {
    commit('_setlocale', locale);
  },

  getSelfCompany: async ({ commit }) => {
    try {
      const { name, id } = await companyAPI.getSelfCompany();

      const company = {
        company_id: Number(id),
        company_name: name,
      };

      commit('_setSelfCompany', company);
    } catch (error) {
      dispatch(
        'core$notifications/add',
        { message: error, type: NoticeEnumTypes.error, source: 'getSelfCompany', title: 'Error' },
        { root: true },
      );
    }
  },

  setSelfCompany: async ({ commit, state }, company_id) => {
    const company = state.companies.find((el) => el.company_id === company_id);

    commit('_setSelfCompany', company);
  },

  getAvailableCompanies: async ({ commit }) => {
    try {
      const companies = await companyAPI.getAvailableCompanies();
      commit('_setAvailableCompanies', companies);
    } catch (error) {
      dispatch(
        'core$notifications/add',
        { message: error, type: NoticeEnumTypes.error, source: 'getAvailableCompanies', title: 'Error' },
        { root: true },
      );
    }
  },

  updateSelfCompany: async ({ commit, dispatch }, id) => {
    try {
      const company_responce = await companyAPI.updateSelfCompany({
        id,
      });

      commit('_setSelfCompany', company_responce);
    } catch (error) {
      dispatch(
        'core$notifications/add',
        { message: error, type: NoticeEnumTypes.error, source: 'updateSelfCompany', title: 'Error' },
        { root: true },
      );
    }
  },

  bindEmployeeToCompany: async ({ commit, state, dispatch }, company) => {
    try {
      const company_responce = await companyAPI.bindEmployeeToCompany({
        company_id: company.company_id,
      });

      commit('_setSelfCompany', company_responce);

      dispatch(
        'core$notifications/add',
        {
          message: `Компания-партнёр ${state.company.company.name} успешно прикреплена.`,
          type: NoticeEnumTypes.success,
          source: 'bindEmployeeToCompany',
          title: 'Компания-партнёр прикреплена',
        },
        { root: true },
      );

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      dispatch(
        'core$notifications/add',
        { message: error, type: NoticeEnumTypes.error, source: 'bindEmployeeToCompany', title: 'Error' },
        { root: true },
      );
    }
  },
};

const mutations = {
  _setlocale: (state, payload) => (state.locale = payload),
  _setSelfCompany: (state, payload) => (state.company = payload),
  _setAvailableCompanies: (state, payload) => (state.companies = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
