import moment from "moment"

export default {
    install: (app, options) => {
        const formatTimestamp = (val, format = 'YYYY-MM-DD HH:mm:ss') => {
            return moment(val).format(format)
        }

        app.config.globalProperties.$ts = (val, format) => {
            return formatTimestamp(val, format)
        }
    }
}