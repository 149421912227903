
import { defineComponent } from 'vue';

interface Snowflake {
  id: string;
  top: number;
  left: number;
  size: number;
  opacity: number;
  text: emoji;
  delta: number;
  rotateSpeed: number;
  waveWidth: number;
  waver: Function;
  invertRotate: boolean;
}

type emoji = string;

// prettier-ignore
let textOptions: emoji[] = [
  '❄️', '❄️', '❄️', '❄️', '❄️', '❄️', '❄️', '❄️', '❄️', '❄️',
  '❄️', '❄️', '❄️', '❄️', '❄️', '❄️', '❄️', '❄️', '❄️', '❄️',
  '❄️', '❄️', '❄️', '❄️', '❄️', '❄️', '❄️', '❄️', '❄️', '❄️',
  '🎄',
  '✨',
  // '🎅',
  '🎁',
  '💫',
  '⭐',
];

let deerNames: string[] = ['Dasher', 'Dancer', 'Prancer', 'Vixen', 'Comet', 'Cupid', 'Dunder', 'Blixem'];

let snowflakes: { [key: string]: Snowflake } = {};

export default defineComponent({
  data() {
    return {
      snowflakes: [] as Snowflake[],
      doCreate: true as boolean,
      animationFrameId: null as number,

      tangerineClick: 0 as number,
      tangerineQuest: false as boolean,

      deerCatched: [] as string[],
      deerQuest: false as boolean,

      deerNames,
    };
  },

  methods: {
    handleClick(snowflake: Snowflake): void {
      if (snowflake.text === '🎁') {
        alert('Nice try, you got a gift!\n\nYou now need to find the chocolate 🍫...');

        textOptions.push('🍫');
      }

      if (snowflake.text === '🍫') {
        alert('Cool! It seems you like chocolate...\n\nGo find the tangerine 🍊!');

        textOptions = textOptions.filter((el) => el !== '🍫');
        textOptions.push('🍊');
      }

      if (snowflake.text === '🍊') {
        if (this.tangerineQuest) {
          this.tangerineClick++;

          if (this.tangerineClick === 10) {
            setTimeout(() => {
              alert('Good one!\n\nNow you need to find the 8 deers 🦌 of Santa!');

              // prettier-ignore
              textOptions = [
                '❄️', '❄️', '❄️', '❄️', '❄️', '❄️', '❄️', '❄️', '❄️', '❄️',
                '🦌',
              ];

              this.tangerineQuest = false;
              this.tangerineClick = 0;

              this.deerQuest = true;
              this.deerCatched = [];
            }, 200);
          }
        } else {
          alert('Tangerines for everyone! 🍊🍊🍊\n\nClick on 10 tangerines for the next surprise!');

          textOptions = ['🍊'];
          this.tangerineQuest = true;
          this.tangerineClick = 0;
        }
      }

      if (snowflake.text === '🦌') {
        // TODO: delete deer

        const randomNames = this.deerNames.map((x) => x).sort(() => Math.round(Math.random() * 2 - 1));

        for (let index = 0; index < randomNames.length; index++) {
          const element = randomNames[index];

          if (this.deerCatched.indexOf(element) === -1) {
            this.deerCatched.push(element);
            break;
          }
        }

        if (this.deerCatched.length === this.deerNames.length) {
          setTimeout(() => {
            // TODO: show Santa
            alert('Game Complete!');

            textOptions = ['❄️'];
            this.deerQuest = false;
            this.deerCatched = [];
          }, 200);
        }
      }
    },

    snowflakesList(snowflakes: { [key: string]: Snowflake }): Snowflake[] {
      return Object.entries(snowflakes).map(([, value]: [string, Snowflake]) => value);
    },

    createSnowflake(): void {
      const snowflake: Snowflake = {
        id: Date.now() + '~' + Math.random(),
        text: textOptions[Math.floor(Math.random() * textOptions.length)],
        top: -50,
        left: document.documentElement.clientWidth * Math.random(),
        size: 10 * Math.random() + 20,
        opacity: 0.8 * Math.random() + 0.2,
        delta: Math.random() * 2 + 1,
        // delta: 0.5,
        rotateSpeed: Math.random() * 8 + 2,
        waveWidth: 10 * Math.random(),
        waver: Math.round(Math.random()) ? Math.sin : Math.cos,
        invertRotate: !!Math.round(Math.random()),
      };

      snowflakes[snowflake.id] = snowflake;
    },

    snowflakeCss(snowflake: Snowflake): object {
      let css = {
        top: `${snowflake.top}px`,
        left: `${snowflake.left}px`,
        'font-size': `${snowflake.size}px`,
        opacity: snowflake.opacity,
        'animation-duration': `${snowflake.rotateSpeed}s`,
        'animation-direction': snowflake.invertRotate ? 'reverse' : 'normal',
      };

      // prettier-ignore
      if (
        snowflake.text === '🎁'
        || snowflake.text === '🍫'
        || snowflake.text === '🍊'
        || snowflake.text === '🦌'
      ) {
        css['pointer-events'] = 'all';
      }

      return css;
    },

    updateSnowflake(snowflake: Snowflake): void {
      snowflake.top += snowflake.delta;
      snowflake.left += snowflake.waver(Date.now() / 1000) * snowflake.waveWidth;
    },

    deleteSnowflake(snowflake: Snowflake): void {
      delete snowflakes[snowflake.id];
    },

    creator(): void {
      const area = Math.sqrt(document.documentElement.clientHeight * document.documentElement.clientWidth);
      const ratio = 20 / Math.sqrt(1920 * 1080);
      const max = Math.max(Math.min(area * ratio, 40), 10);

      if (this.snowflakesList(snowflakes).length < max) {
        this.createSnowflake();
      }

      this.doCreate && setTimeout(this.creator, 100 * Math.random() + 100);
    },

    frame(): void {
      this.snowflakes = this.snowflakesList(snowflakes);

      this.snowflakesList(snowflakes).forEach((value: Snowflake) => {
        this.updateSnowflake(value);

        if (value.top > document.documentElement.clientHeight + 50) {
          setTimeout(() => {
            this.deleteSnowflake(value);
          }, 500);
        }
      });

      this.animationFrameId = window.requestAnimationFrame(this.frame);
    },
  },

  mounted() {
    this.creator();
    this.frame();
  },

  unmounted() {
    this.doCreate = false;
    window.cancelAnimationFrame(this.animationFrameId);
  },
});
