import { createI18n } from 'vue-i18n';
import { utils } from '@brskl/core';

import { ru, kz, hr, ua, gr, tr, en, lv } from '@/core/locales/';

export const translateLocales = {
  ru: { id: 1, name: 'RU', label: 'ru', locale: 'ru', icon: 'ru', displayName: 'Русский' },
  en: { id: 7, name: 'EN', label: 'en', locale: 'en', icon: 'us', displayName: 'English' },
};
//ISO 639-1
const locales = { ru, kz, hr, ua, tr, gr, en, lv };

const getLocale = () => {
  const preferredLang = localStorage.getItem('TLocale');
  if (!preferredLang || typeof preferredLang !== 'string') {
    const userLoacale = navigator.language.slice(0, 2);
    if (Object.keys(locales).includes(userLoacale)) {
      return userLoacale;
    } else {
      return 'ru';
    }
  }
  return preferredLang.toString();
};

const instance = createI18n({
  legacy: true,
  locale: getLocale(),
  messages: locales,
  fallbackLocale: IS_DEV ? [] : ['ru'],
  pluralizationRules: {
    ru: function (choice, lng) {
      return utils.getSlavicPluralIndex(choice);
    },
  },
});

export const getI18n = () => {
  return instance.global;
};

export { instance as i18n };
