import steps from '../data/stepList.js';
import { companyAPI } from '@/core/api/company';
import { format } from 'date-fns';

const state = {
  steps: steps(),
  period: {
    start: '',
    end: '',
  },

  selectedTradepoints: [],
  selectedItems: [],

  newReportTableData: [],
  newReportID: null,
  newReportMeta: [],
};

const getters = {
  currentStep: (state) => state.steps.indexOf(state.steps.find((el) => el.isActive)),
  steps: (state) => state.steps,
  period: (state) => state.period,
  selectedTradepoints: (state) => state.selectedTradepoints,
  selectedItems: (state) => state.selectedItems,
  newReportTableData: (state) => state.newReportTableData,
  newReportMeta: (state) => state.newReportMeta,
  newReportID: (state) => state.newReportID,
};

const actions = {
  changeStep: async ({ commit }, index) => {
    commit('_changeStep', index);
  },

  completeStep: async ({ commit }, payload) => {
    commit('_completeStep', payload);
  },

  clearSteps: async ({ commit }) => {
    commit('_clearSteps');
  },

  setPeriod: ({ commit }, period) => {
    commit('_setPeriod', period);
  },

  setSelectedTradepoints: ({ commit }, tradepoints) => {
    commit('_setSelectedTradepoints', tradepoints);
  },

  setSelectedItems: ({ commit }, items) => {
    commit('_setSelectedItems', items);
  },

  setDateFormat: ({ state }) => {
    //YYYY-mm-dd HH:ii:ss
    const dateStart = format(state.period.start, 'yyyy-MM-dd') + ' 00:00:00';
    const dateEnd = format(state.period.end, 'yyyy-MM-dd') + ' 23:59:59';
    return { dateStart, dateEnd };
  },

  createReport: async ({ commit, state, dispatch }) => {
    const period = await dispatch('setDateFormat');
    const store_id = state.selectedTradepoints.map((tradepoint) => tradepoint.id);
    const item_id = state.selectedItems.map((item) => item.id);

    const params = {
      fields: {
        item_id: 'item_movement_position.item_id',
        unit_name: 'unit.name',
        store_id: 'item_movement.store_id',
        store_name: 'store.name',
        item_name: 'item_movement_position.item_name',
        total_demand: 'total_demand',
        total_supply: 'total_supply',
        item_amount: 'item_amount',
        timestamp_inserting: 'timestamp_inserting',
        item_barcode: 'item_movement_position.item_barcode',
        item_stock_end_report_period: 'item_stock_end_report_period',
        item_stock_start_report_period: 'item_stock_start_report_period',
      },
      filters: {
        report_start_date: period.dateStart,
        report_till_date: period.dateEnd,
        store_id,
        item_id,
      },
      grouping: ['item_movement.store_id', 'item_movement_position.item_id'],
    };
    try {
      const { items, meta } = await companyAPI.createStorageReport(params);
      commit('_setNewReportTableData', items);
      commit('_setNewReportMeta', meta);
      commit('_setNewReportID', meta.report_id);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  _changeStep: (state, index) => {
    state.steps.find((el) => el.isActive).isActive = false;
    state.steps[index].isActive = true;
  },
  _completeStep: (state, payload) => (state.steps[payload.index].completed = payload.status),
  _clearSteps: (state) => state.steps.map((step) => (step.completed = false)),
  _setPeriod: (state, payload) => (state.period = payload),
  _setSelectedTradepoints: (state, payload) => (state.selectedTradepoints = payload),
  _setSelectedItems: (state, payload) => (state.selectedItems = payload),

  _setNewReportMeta: (state, payload) => (state.newReportMeta = payload),
  _setNewReportTableData: (state, payload) => (state.newReportTableData = payload),
  _setNewReportID: (state, payload) => (state.newReportID = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
