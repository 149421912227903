<template>
  <div :class="['chip', { selected: selected }]">{{ text }}</div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/core/styles/mixins';

.chip {
  transition: all 0.2s linear;
  border: 1px solid #6a7b95;
  border-radius: rem(36px);
  justify-content: center;
  line-height: rem(16px);
  font-size: rem(12px);
  align-items: center;
  height: rem(28px);
  font-weight: 500;
  color: #6a7b95;
  padding: 0 12px;
  cursor: pointer;
  display: flex;

  &.selected {
    border-color: #22c951;
    color: #22c951;
  }
}
</style>
