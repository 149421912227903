/* eslint-disable no-shadow, no-param-reassign */

const state = {
  size: 0,
  browser: '',
};

const getters = {
  size: (state) => state.size,
  deviceIs: (state) => {
    if (state.size < 767) return 'mobile';
    if (state.size < 1200) return 'tablet';
    return 'desktop';
  },
  browser: (state) => state.browser,
};

const actions = {};

const mutations = {
  setWindowSize: (state) => (state.size = document.documentElement.clientWidth),
  setUserBrowser: (state) => {
    switch (navigator.vendor) {
      case 'Google Inc.':
        state.browser = 'Chrome';
        break;
      case 'Apple Computer, Inc.':
        state.browser = 'Safari';
        break;
      case '':
        state.browser = 'Firefox';
        break;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
