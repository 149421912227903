// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('Вопросы и ответы'),
    route: {name: 'help$faq'}
  },
];

export default [
  {
    path: '/help/faq',
    name: 'help$faq',
    component: () => import('@/apps/help/views/FaqFrame.vue'),
    meta: {
      breadCrumb: [breadCrumb[0]],
    },
  },
]
