<template>
  <div class="notifications">
    <RegularNotification
      v-for="item in items"
      :key="item.id"
      :item="item"
      @close="onClose"
    />
    <!-- <div v-show="items.length > 2" class="closeButton">
      <Button @click="onCloseAll">{{ $t("Скрыть все уведомления") }}</Button>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import RegularNotification
  from '@/core/components/organism/Notifications/RegularNotification/RegularNotification';

export default {
  components: {
    RegularNotification,
  },

  computed: {
    ...mapGetters({ items: 'core$notifications/items' }),
  },

  methods: {
    ...mapActions({
      remove: 'core$notifications/remove',
      removeAll: 'core$notifications/removeAll',
    }),

    computeMessageComponent(type) {
      // ChatMessage - для оповещения о новых сообщениях в чатах
      // RegularNotification - системные сообщения
      return type === 'chat-message' ? 'ChatMessage' : 'RegularNotification';
    },

    onClose(id) {
      this.remove(id);
    },

    onCloseAll() {
      this.removeAll();
    },
  },
};
</script>

<style src="./Notifications.scss" lang="scss" scoped></style>
