import store from "@/core/store";
import docs from "./store";

export const connect = (opts = {}) => {
  try {
    store.registerModule("documents$docs", docs);
  } catch (e) {
    console.log(e);
  }
};
