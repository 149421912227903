import { api } from '@brskl/core';
import omit from 'lodash/omit';
import qs from 'qs';

const removeQueryFromUrl = () => {
  const [url, queryString] = window.location.href.split('?');
  const queryWithoutTokens = qs.stringify(omit(qs.parse(queryString), ['access_token', 'refresh_token']));
  const newUrl = url + (queryWithoutTokens ? `?${queryWithoutTokens}` : '');

  history.replaceState({}, document.title, newUrl);
};

const getTokensFromQuery = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return {
    access_token: urlParams.get('access_token'),
    refresh_token: urlParams.get('refresh_token'),
  };
};

const checkCartRoute = () => {
  return location.href.includes('carts');
};

export const checkTokens = () => {
  if (!api.tokens && checkCartRoute()) return;

  const tokens = getTokensFromQuery();
  removeQueryFromUrl();

  const isTokensExist = Object.values(tokens).every((v) => !!v);

  if (isTokensExist) {
    api.tokens.saveTokens({ ...tokens, session: true }, false);
    return tokens;
  }
  return;
};
