import { parse, format as _format } from 'date-fns';
import { ru } from 'date-fns/locale';
import moment from 'moment';

const getDateInterval = ({ start, end }) => {
  const dayStart = moment(start).startOf('day');
  const dayEnd = end ? moment(end).endOf('day') : moment(start).endOf('day')

  return {
    start: dayStart.format(),
    end: dayEnd.format()
  }
};

const format = (date, formatStr) => {
  const _date = typeof date === 'string' ? new Date(date) : date;
  return _format(_date, formatStr, {
    locale: ru, // add localization
  });
};

export { getDateInterval, format };
