/* eslint-disable no-shadow, no-param-reassign */
import { companyAPI } from '../api/company';

type TState = typeof state;

const initState = () => ({
  searchStringValue: '' as string,
  products: [],
  checkboxValue: false as boolean,
  isLoadAllValues: false as boolean,
});

const state = initState();

const getters = {
  searchStringValue: (state: TState) => state.searchStringValue as string,
  products: (state: TState) => state.products,
  checkboxValue: (state: TState) => state.checkboxValue as boolean,
  isLoadAllValues: (state: TState) => state.isLoadAllValues as boolean,
};

/* if expand button pressed, limit will be 999, if off 3, default is 3 */
/* if checkbox in searchbar is on, search will be global, if off - only at category with the same alias */

const actions = {
  getProducts: async ({ commit, getters }, alias: string) => {
    const options = {
      limit: getters.isLoadAllValues ? 999 : 3,
      object_alias: getters.checkboxValue ? '' : alias,
      search_string: getters.searchStringValue,
      step_limit: 0 /* pagination option */,
    };

    try {
      if (options.search_string.length === 0) {
        return;
      } else {
        const products = await companyAPI.getGlobalSearch(options);
        commit('_setItems', products.found_items);
      }
    } catch (error) {
      console.error(error);
    }
  },

  setSearch: ({ commit }, value: string) => {
    commit('_setSearch', value);
  },

  setCheckboxValue: ({ commit }, value: boolean) => {
    commit('_setCheckboxValue', value);
    commit('_setSearch', '');
  },

  setIsLoadAllValues: ({ commit }, value: boolean) => {
    commit('_setIsLoadAllValues', value);
  },
};

const mutations = {
  setProducts: (state: TState, products) => (state.products = products),

  _setSearch: (state: TState, value: string) => {
    state.searchStringValue = value;
  },

  _setCheckboxValue: (state: TState, checkboxValue: boolean) => (state.checkboxValue = checkboxValue),

  _setIsLoadAllValues: (state: TState, isLoadAllValues: boolean) => (state.isLoadAllValues = isLoadAllValues),

  _setItems: (state: TState, products) => {
    const productsArray = Object.entries(products).map(([key, value]) => {
      //@ts-ignore
      return value.map((element) => {
        const innerArrayOfItems = element.json_data.items;
        /* if key doesnt in the item, it will be not incude in the obj below */
        return innerArrayOfItems.map((item) => {
          const obj = {
            ...(key && { object_alias: key }) /* aliases for all blocks */,
            ...(element.object_id && { object_id: element.object_id }) /* for all blocks */,
            ...(element.id && { disc_id: element.id }) /* for all blocks */,
            ...(element.json_data.id && { id: element.json_data.id }) /* order id */,
            ...(item.name && { name: item.name }) /* item name for multiple */,
            ...(item.item_id && { item_id: item.item_id }) /* item.id for multiple */,
            ...(item.address_en && { address_en: item.address_en }) /* address for companies en */,
            ...(item.address && { address: item.address }) /* address for companies ru */,
            ...(item.type && { type: item.type }) /* type of tradepoint */,
            ...(item.party_name && { party_name: item.party_name }) /* name for company */,
            ...(item.discount && { discount: item.discount }) /* discount for item no data for set and for cat-ry*/,
            ...(item.first_name && { first_name: item.first_name }) /* employee */,
            ...(item.last_name && { last_name: item.last_name }) /* employee */,
            ...(item.phone && { phone: item.phone }) /* employee */,
            ...(element.json_data.status && { status: element.json_data.status }) /* for order? */,
            ...(item.item_name && { item_name: item.item_name }),
            ...(item.count && { count: item.count }),
            ...(item.total_amount && { total_amount: item.total_amount }),
            ...(item.sorting && { sorting: item.sorting }),
            ...(item.position_weight && { position_weight: item.position_weight }),
            ...(item.price && { price: item.price }),
            ...(item.quantity && { quantity: item.quantity }),
            ...(item.article && { article: item.article }),
            ...(item.name_en && { name_en: item.name_en }),
            ...(item.code && { code: item.code }),
            ...(item.party && { party: item.party }),
            ...(item.company_id && { company_id: item.company_id }),
            ...(item.activation_status && { activation_status: item.activation_status }) /* for entities */,
          };
          return obj;
        })[0];
      });
    });

    //@ts-ignore
    state.products = productsArray;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
