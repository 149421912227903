import { companyAPI } from '@/core/api/company';

const state = {
  menu: [],
  sidebarCollapsed: false,
};

const getters = {
  menu: (state) => state.menu,
  sidebarCollapsed: (state) => state.sidebarCollapsed,
};
const actions = {
  getMenu: async ({ commit }) => {
    const menu = await companyAPI.getMenu();

    // const mockMenu = [...menu, {
    //   title: 'Сервисный центр',
    //   icon: 'service',
    //   children: [
    //     {
    //       title: 'Оборудование',
    //       route: {
    //         name: 'service-devices$list',
    //       },
    //     },
    //     {
    //       title: 'Документы',
    //       route: {
    //         name: 'service-documents$list',
    //       },
    //     },
    //     {
    //       title: 'Контрагенты',
    //       route: {
    //         name: 'service-contractors$list',
    //       },
    //     },
    //   ],
    // }];

    commit('_setMenu', menu);
  },
};
const mutations = {
  _setMenu: (state, payload) => (state.menu = payload),
  setSidebarCollapsed: (state, value) => state.sidebarCollapsed = value,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
