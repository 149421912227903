// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

export default () => [
  {
    title: i18n.t('Период'),
    isActive: true,
    completed: false,
    component: 'Period',
  },
  {
    title: i18n.t('Торговые точки'),
    isActive: false,
    completed: false,
    component: 'Tradepoints',
  },
  {
    title: i18n.t('Товары'),
    isActive: false,
    completed: false,
    component: 'Items',
  },
];
