import { companyAPI } from '@/core/api/company';
import { formatItems } from '@/apps/main/data/chartDateMethods';
import { NoticeEnumTypes } from '@/core/data/Notice-enum';

const state = {
  tradePointsStatuses: {},
  statistics: {},
};

const getters = {
  tradePointsStatuses: (state) => state.tradePointsStatuses,
  isNewUser: (state) => state.tradePointsStatuses.total === 0,
  statistics: (state) => state.statistics,
};

const actions = {
  getTradePointsStatuses: async ({ commit }) => {
    try {
      const statuses = await companyAPI.getTradePointStatus();
      commit('_setTradePointsStatuses', statuses);
    } catch (error) {
      console.error('tradePointsStatuses error:', error);
    }
  },
  getStatistics: async ({ commit, dispatch }, data) => {
    try {
      const statistics = await companyAPI.getStatistics(data);
      commit('_setStatistics', statistics);
    } catch (error) {
      console.error('statistics error:', error);
      // TODO: Антону ради примера
      dispatch(
        'core$notifications/add',
        { message: error.error.message, type: NoticeEnumTypes.error, title: 'error' },
        { root: true },
      );
    }
  },
};

const mutations = {
  _setTradePointsStatuses: (state, statuses) => {
    state.tradePointsStatuses = statuses;
  },
  _setStatistics: (state, statistics) => {
    state.statistics = formatItems(statistics);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
