<template>
  <div :class="['notification-item', `notification-item--${type}`]">
    <div class="item__header">
      <Icon :name="type === 'error' ? 'icon_cross' : 'icon_checkmark'"
            class="item__header-icon u-mr--1" />
      <span class="item__header-title">{{ title }}</span>
      <!-- <Icon name="icon_cross" @click="onClose" class="item__header-close" /> -->
    </div>

    <div class="item__body u-mt--1 u--ml--3">{{ message }}</div>
    <div v-if="isMessage" class="item__footer">
      <Button @click="onChat">{{ $t('Перейти') }}</Button>
    </div>
  </div>
</template>

<script lang="ts">
import { Button, Icon } from '@brskl/ui-lib';
import { NoticeEnumTypes } from '@/core/data/Notice-enum';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

export default defineComponent({
  emits: ['close'],

  components: {
    //@ts-ignore
    Icon,
    //@ts-ignore
    Button,
  },
  //@ts-ignore
  computed: {
    type() {
      return this.item?.type || NoticeEnumTypes.default;
    },

    isMessage() {
      return this.type === 'chat-message';
    },

    title() {
      return this.item?.title || '';
    },

    message() {
      if (this.type === 'error') {
        return !!this.item.message.error
          ? this.$t(this.item?.message.error.message)
          : this.$t('Что то пошло не так, попробуйте перезагрузить страницу') || '';
      } else {
        return this.item?.message.substring(0, 120) || '';
      }
    },
  },

  props: {
    item: {
      type: Object,
    },
  },

  methods: {
    ...mapActions({
      setChat: 'core$notifications/setChat',
    }),
    onClose() {
      this.$emit('close', this.item.id);
    },
    autoClose() {
      setTimeout(this.onClose, 7000);
    },
    onChat() {
      this.onClose();
      this.setChat(true);
    },
  },

  mounted() {
    this.autoClose();
  },
});
</script>

<style src="./RegularNotification.scss" lang="scss" scoped></style>
