import { createStore } from 'vuex';
import core$browser from '@/core/stores/browser';
import core$main from '@/core/stores/main';
import core$employeePreferences from '@/core/stores/employeePreferences';
import core$globalSearch from '@/core/stores/globalSearch';
import core$notifications from '@/core/stores/notifications';
import core$company from '@/core/stores/company';
import core$menu from '@/core/stores/menu';

const store = createStore({
  modules: {
    core$browser,
    core$globalSearch,
    core$main,
    core$company,
    core$employeePreferences,
    core$notifications,
    core$menu,
  },
});

export default store;
