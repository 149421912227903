// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('Главная'),
    route: { name: 'main' },
  },
  {
    label: i18n.t('Обновления'),
    route: { name: 'patchnotes' },
  },
];

export default [
  {
    path: '/home',
    name: 'main',
    component: () => import('@/apps/main/views/Main'),
    meta: {
      breadCrumb: [breadCrumb[0]],
      title: i18n.t('Главная'),
    },
  },
  {
    path: '/patchnotes',
    name: 'patchnotes',
    component: () => import('@/apps/main/views/Patchnotes/Patchnotes'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
      title: i18n.t('Обновления'),
    },
  },
];
