import { TServiceCenterDevice } from '@/apps/service_center/types/devices';

const state = {
  selectedDevices: [] as TServiceCenterDevice[],
};

const getters = {
  selectedDevices: (state) => state.selectedDevices,
};

const actions = {
  clearSelectedDevices: ({ commit }) => {
    commit('_clearSelectedDevices');
  },
  setSelectedDevices: ({ commit }, devices: TServiceCenterDevice[]) => {
    commit('_setSelectedDevices', devices);
  },
};

const mutations = {
  _clearSelectedDevices: (state) => {
    state.selectedDevices = [];
  },
  _setSelectedDevices: (state, payload: TServiceCenterDevice[]) => {
    state.selectedDevices = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
