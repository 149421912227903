import { getI18n } from '@/core/i18n';

const i18n = getI18n();

const breadCrumb = [
  {
    label: i18n.t('Реклама на терминале'),
    route: { name: 'terminal-promo$terminal-promo' },
  },
  {
    label: '',
    route: { name: 'terminal-promo$detail' },
  },
  {
    label: i18n.t('Новый рекламный блок'),
    route: { name: 'terminal-promo$create' },
  },
];

export default [
  {
    path: '/terminal-promo',
    name: 'terminal-promo$terminal-promo',
    component: () => import('@/apps/terminal-promo/views/TerminalPromoGrid.vue'),
    meta: {
      breadCrumb: [breadCrumb[0]],
    },
  },
  {
    path: '/terminal-promo/create',
    name: 'terminal-promo$create',
    component: () => import('@/apps/terminal-promo/views/TerminalPromoDetail.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[2]],
    },
  },
  {
    path: '/terminal-promo/:uuid',
    name: 'terminal-promo$detail',
    component: () => import('@/apps/terminal-promo/views/TerminalPromoDetail.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
    },
  },
];
