// import create_coffeemachine from './views/Create/CoffeeMachine/store/create';
import exchange from './store/exchange';
import create from './store/create';
import detail from './store/detail';
import store from '@/core/store';
import edit from './store/edit';
import list from './store/list';

export const connect = (opts = {}) => {
  store.registerModule('trade-points$exchange', exchange);
  store.registerModule('trade-points$create', create);
  store.registerModule('trade-points$detail', detail);
  store.registerModule('trade-points$edit', edit);
  store.registerModule('trade-points$list', list);
};
