// --- INJECTED ---
import { getI18n } from '@/core/i18n';

const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('Каталоги и категории'),
    route: { name: 'catalogs-categories$catalogs-categories' },
  },
  {
    label: i18n.t('Категория'),
    route: { name: 'catalogs-categories$category' },
  },
  {
    label: i18n.t('Каталог'),
    route: { name: 'catalogs-categories$catalog' },
  },
  {
    label: i18n.t('Новый каталог'),
    route: { name: 'catalogs-categories$create-catalog' },
  },
  {
    label: i18n.t('Новая категория'),
    route: { name: 'catalogs-categories$create-category' },
  },
];

export default [
  {
    path: '/catalogs-categories',
    name: 'catalogs-categories$catalogs-categories',
    component: () => import('@/apps/catalogs-categories/views/CatalogsCategories'),
    redirect: { name: 'catalogs-categories$catalogs' },
    meta: {
      breadCrumb: breadCrumb.slice(0, 1),
      title: i18n.t('Каталоги и категории'),
    },
    children: [
      {
        path: 'catalogs',
        name: 'catalogs-categories$catalogs',
        component: () => import('@/apps/catalogs-categories/views/Catalogs/Catalogs'),
        meta: {
          breadCrumb: breadCrumb.slice(0, 1),
          title: i18n.t('Каталоги'),
        },
      },
      {
        path: 'categories',
        name: 'catalogs-categories$categories',
        component: () => import('@/apps/catalogs-categories/views/Categories/Categories'),
        alias: 'catalogs',
        meta: {
          breadCrumb: breadCrumb.slice(0, 1),
          title: i18n.t('Категории'),
        },
      },

    ],
  },
  {
    path: '/catalog/create',
    name: 'catalogs-categories$create-catalog',
    component: () => import('@/apps/catalogs-categories/views/Catalogs/Catalog/Catalog'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[3]],
      title: i18n.t('Новый каталог'),
    },
  },
  {
    path: '/category/create',
    name: 'catalogs-categories$create-category',
    component: () => import('@/apps/catalogs-categories/views/Categories/Category/Category'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[4]],
      title: i18n.t('Новая категория'),
    },
  },
  {
    path: '/catalogs/:id',
    name: 'catalogs-categories$catalog',
    component: () => import('@/apps/catalogs-categories/views/Catalogs/Catalog/Catalog'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[2]],
      title: i18n.t('Каталог'),
    },
  },
  {
    path: '/categories/:id',
    name: 'catalogs-categories$category',
    component: () => import('@/apps/catalogs-categories/views/Categories/Category/Category'),
    meta: {
      breadCrumb: breadCrumb.slice(0, 2),
      title: i18n.t('Категория'),
    },
  },


];
