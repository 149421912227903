const state = {
  isFooterOpened: false,
};

const getters = {
  isFooterOpened: (state) => state.isFooterOpened,
};

const actions = {
  toggleFooter: ({ commit, state }, payload) => {
    commit('_toggleFooter', payload);
  },
};

const mutations = {
  _toggleFooter: (state, payload) => (state.isFooterOpened = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
