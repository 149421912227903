export const enum NoticeEnumTypes {
  default = 'default',
  error = 'error',
  success = 'success',
  warning = 'warning',
  new_client = 'newclient',
}

export const enum OrderEventTypes {
  CREATE = 11, // icon_start
  UPDATE = 12,
  DELETE = 13, // icon_delete
  PAID = 14, //
  REFUND = 15, //

  CREATE_POSITION = 21,
  UPDATE_POSITION = 22,
  DELETE_POSITION = 23,
  INCREASE_POSITION = 24,
  DECREASE_POSITION = 25,
}
