// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('Скидки'),
    route: { name: 'discounts$discounts' },
  },
  {
    label: 'ID :id',
    route: { name: 'discounts$for-item' },
  },
  {
    label: 'ID :id',
    route: { name: 'discounts$for-category' },
  },
  {
    label: '',
    route: { name: 'discounts$for-order' },
  },
  {
    label: i18n.t('Новая скидка'),
    route: { name: 'discounts$create-discount' },
  },
];

export default [
  {
    path: '/discounts',
    name: 'discounts$discounts',
    redirect: { name: 'discounts$for-item' },
    component: () => import('@/apps/discounts/views/Discounts.vue'),
    meta: {
      breadCrumb: breadCrumb.slice(0, 1),
      title: i18n.t('Скидки'),
    },
    children: [
      {
        path: 'for-item',
        name: 'discounts$for-item',
        component: () => import('@/apps/discounts/views/tabs/ForItem/ForItem.vue'),
        meta: {
          title: i18n.t('Скидки на товары'),
        }
      },
      {
        path: 'for-category',
        name: 'discounts$for-category',
        component: () => import('@/apps/discounts/views/tabs/ForCategory/ForCategory.vue'),
        meta: {
          title: i18n.t('Скидки на категорию'),
        }
      },
      {
        path: 'for-order',
        name: 'discounts$for-order',
        component: () => import('@/apps/discounts/views/tabs/ForOrder/ForOrder.vue'),
        meta: {
          title: i18n.t('Скидки на заказ'),
        }
      },
    ],
  },
  {
    path: '/discounts/for-item/:id',
    name: 'discounts$for-item-detail',
    component: () => import('@/apps/discounts/views/ItemDiscount/ItemDiscount.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
      title: i18n.t('Скидки на товар'),
    },
  },
  {
    path: '/discounts/for-category/:id',
    name: 'discounts$for-category-detail',
    component: () => import('@/apps/discounts/views/CategoryDiscount/CategoryDiscount.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[2]],
      title: i18n.t('Скидки на категорию'),
    },
  },
  {
    path: '/discounts/for-order/:id',
    name: 'discounts$for-order-detail',
    component: () => import('@/apps/discounts/views/OrderDiscount/OrderDiscount.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[3]],
      title: i18n.t('Скидки на заказ'),
    },
  },
  {
    path: '/discounts/create-discount',
    name: 'discounts$create-discount',
    component: () => import('@/apps/discounts/views/CreateDiscount/CreateDiscount.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[4]],
      title: i18n.t('Добавление скидки'),
    },
  },
];
