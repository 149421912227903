import { getDateInterval } from '@/core/utils/date';
import { sub, startOfToday, endOfToday, differenceInCalendarDays } from 'date-fns';
import moment from 'moment';

const formatChartData = ({ date_start, date_end, mode = 'days', status = 'paid' }) => {
  return {
    date_start,
    date_end,
    mode,
    status,
  };
};

const today = () => formatChartData({
  date_start: moment().startOf('day').format(),
  date_end: moment().endOf('day').format(),
  mode: 'hours',
});

const month = () => {
  const now = new Date();
  const monthAgo = sub(now, { months: 1 });
  const { start, end } = getDateInterval({ start: monthAgo, end: now });
  return formatChartData({
    date_start: start,
    date_end: end,
  });
};

const halfyear = () => {
  const now = new Date();
  const monthAgo = sub(now, { months: 6 });
  const { start, end } = getDateInterval({ start: monthAgo, end: now });
  return formatChartData({
    date_start: start,
    date_end: end,
    mode: 'months',
  });
};

const year = () => {
  const now = new Date();
  const monthAgo = sub(now, { years: 1 });
  const { start, end } = getDateInterval({ start: monthAgo, end: now });
  return formatChartData({
    date_start: start,
    date_end: end,
    mode: 'months',
  });
};

const selectPeriod = ({ start, end }) => {
  const dateInterval = getDateInterval({ start, end });
  const startInterval = new Date(dateInterval.start);
  const endInterval = new Date(dateInterval.end);
  const isMore31days = Math.abs(differenceInCalendarDays(startInterval, endInterval)) > 31;

  return formatChartData({
    date_start: dateInterval.start,
    date_end: dateInterval.end,
    mode: isMore31days ? 'months' : 'days',
  });
};

const selectDate = (date) => {
  const dateInterval = getDateInterval({ start: date });
  return formatChartData({
    date_start: dateInterval.start,
    date_end: dateInterval.end,
    mode: 'hours',
  });
};

//

export const getChartData = (type, date) => {
  if (type === 'today') return today();
  if (type === 'month') return month();
  if (type === 'halfyear') return halfyear();
  if (type === 'year') return year();
  if (type === 'selectPeriod') return selectPeriod(date);
  if (type === 'selectDate') return selectDate(date);
};

export const formatItems = ({ items, total }) => {
  const props = {
    avg_amount: {
      data: [],
    },
    period: {
      data: [],
    },
    total_count: {
      data: [],
    },
    total_customers: {
      data: [],
    },
    total_amount: {
      data: [],
    },
  };

  items.forEach((item) => {
    Object.entries(item).forEach(([key, value]) => {
      if (!!props[key]) props[key].data.push({ x: item.period, y: value });
    });
  });
  Object.entries(total).forEach(([key, value]) => {
    if (key in props) props[key].total = value;
  });
  return props;
};
