<template>
    <div class="faq-container">
        <iframe src="https://briskly.online/sidebar" frameborder="0"></iframe>
    </div>
</template>

<style>
.faq-container {
    overflow: hidden;
    height: 100%;
}

.faq-container iframe {
    width: 100%;
    height: 100%;
}
</style>