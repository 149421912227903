// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('Перемещения'),
    route: { name: 'transfers$transfers' },
  },
  {
    label: i18n.t('Перемещение :id'),
    route: { name: 'transfers$transfer' },
  },
  {
    label: i18n.t('Новое перемещение'),
    route: { name: 'transfers$create' },
  },
  {
    label: i18n.t('Перемещение :id'),
    route: { name: 'transfers$edit' },
  },
];

export default [
  {
    path: '/storage/transfers',
    name: 'transfers$transfers',
    component: () => import('@/apps/transfer/views/Transfer'),
    meta: {
      breadCrumb: breadCrumb.slice(0, 1),
      title: i18n.t('Склад / Перемещения'),
    },
  },
  {
    path: '/storage/transfers/:id',
    name: 'transfers$transfer',
    component: () => import('@/apps/transfer/views/Detail/Detail'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
      title: i18n.t('Склад / Перемещение'),
    },
  },
  {
    path: '/storage/transfers/create',
    name: 'transfers$create',
    component: () => import('@/apps/transfer/views/Create/Create'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[2]],
      title: i18n.t('Создание перемещения'),
    },
  },
  {
    path: '/storage/transfers/:id/edit',
    name: 'transfers$edit',
    component: () => import('@/apps/transfer/views/Edit/Edit'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[3]],
      title: i18n.t('Редактирование перемещения'),
    },
  },
];
