// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('Онлайн-корзины'), // 0
    route: { name: 'carts$carts' },
  },

  {
    label: i18n.t('Настройки'), // 1
    route: { name: 'carts$links' },
  },
  {
    label: i18n.t('Торговая точка'),
    route: { name: 'tradepoints$detail' }, // 2
  },
  {
    label: i18n.t('Торговые точки'),
    route: { name: 'tradepoints$list' }, // 3
  },
];

export default [
  {
    path: '/tradepoints/:id/carts/',
    name: 'carts$carts',
    component: () => import('@/apps/carts/views/Carts.vue'),
    meta: {
      breadCrumb: [breadCrumb[3], breadCrumb[2], breadCrumb[0]],
      title: i18n.t('Онлайн-корзины'),
    },
  },
  {
    path: '/tradepoints/:id/carts/links',
    name: 'carts$links',
    component: () => import('@/apps/carts/views/CartLinkScreen/CartLinkScreen.vue'),
    meta: {
      breadCrumb: [breadCrumb[2], breadCrumb[0], breadCrumb[1]],
      title: i18n.t('Онлайн-корзины'),
    },
  },
];
