<template>
  <div :class="['sidebar-wrapper', sidebarCollapsed && 'd-force-collapsed', `d-${displayMode}`]" @click.self="toggleForceCollapse">
    <div class="sidebar">
      <div class="sidebar-logo">
        <Icon name="menu" @click="toggleMenu" class="icon-menu" />
        <Icon :name="logo" v-if="logo" class="icon-logo" @click="$router.push({ path: '/' })" />
      </div>

      <div class="sidebar-menu__inner">
        <div class="sidebar-menu hide-scrollbar">
          <Menu @openMenu="openMenu" ref="menu" :profile="profile" />
        </div>
      </div>

      <div class="sidebar-footer">
        <slot name="profile"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from './Menu/Menu.vue';
import { Icon } from '@brskl/ui-lib';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  emits: ['toggleMenu', 'openMenu'],
  components: { Menu, Icon },
  props: {
    logo: String,
    menu: {
      type: Array,
      required: true,
    },
    displayMode: String,
    profile: {
      type: Object,
      default: null,
    },
  },
  // data() {
  //   return {
  //     forceCollapsed: false, // for mobile mode
  //   };
  // },
  methods: {
    ...mapMutations({
      setSidebarCollapsed: 'core$menu/setSidebarCollapsed',
    }),
    toggleMenu() {
      if (this.sidebarCollapsed) {
        this.$refs.menu.toggleSubMenu();
      }
      this.$emit('toggleMenu');
    },
    openMenu() {
      this.displayMode === 'tablet' ? (this.sidebarCollapsed = true) : this.$emit('openMenu');
    },
    toggleForceCollapse() {
      this.setSidebarCollapsed(!this.sidebarCollapsed);
      // this.forceCollapsed = !this.forceCollapsed;
    },
    onLinkClick() {
      if (this.displayMode === 'mobile' || (this.displayMode === 'tablet' && this.sidebarCollapsed)) {
        this.sidebarCollapsed = !this.sidebarCollapsed;
      }
    },
  },
  computed: {
    ...mapGetters({
      localeFromStore: 'profile$profile/locale',
      sidebarCollapsed: 'core$menu/sidebarCollapsed',
    }),
  },
  mounted() {
    this._links = document.getElementsByClassName('sidebar-link');
    Array.prototype.forEach.call(this._links, (link) => {
      link.addEventListener('click', this.onLinkClick);
    });

    this.$nextTick(() => {
      this.$watch(
        () => this.localeFromStore,
        (old, next) => {
          if (old !== next) this.$refs.menu.onMenuComponentUpdate();
        },
      );
    });
  },
  beforeUnmount() {
    Array.prototype.forEach.call(this._links, (link) => {
      link.removeEventListener('click', this.onLinkClick);
    });
  },
};
</script>

<style src="./Sidebar.scss" lang="scss" scoped></style>
