// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('Торговые точки'),
    route: { name: 'tradepoints$list' }, // 0
  },
  {
    label: '',
    route: { name: 'tradepoints$detail' }, // 1
  },
  {
    label: i18n.t('Настройки'),
    route: { name: 'tradepoints$edit' }, // 2
  },
  {
    label: i18n.t('Реквизиты'),
    route: { name: 'tradepoints$edit/requisites' }, // 3
  },
  {
    label: i18n.t('Устройства'),
    route: { name: 'tradepoints$edit/module' }, // 4
  },
  {
    label: i18n.t('Адрес'),
    route: { name: 'tradepoints$edit/map' }, // 5
  },
  {
    label: i18n.t('Новая торговая точка'),
    route: { name: 'tradepoints$create' }, // 6
  },
  {
    label: i18n.t('Привязка каталога'),
    route: { name: 'tradepoints$edit/catalog-bind' }, // 7
  },
  {
    label: i18n.t('Добавление товаров'),
    route: { name: 'tradepoints$edit/integration' }, // 8
  },
  {
    label: i18n.t('Интеграция'),
    route: { name: 'tradepoints$edit/accounting-page' }, // 9
  },
  {
    label: i18n.t('Создание каталога'),
    route: { name: 'tradepoints$edit/catalog-create' }, // 10
  },
  {
    label: i18n.t('Каталог'),
    route: { name: 'tradepoints$edit/new-catalog' }, // 11
  },
  {
    label: i18n.t('Создание товара'),
    route: { name: 'tradepoints$edit/new-item' }, // 12
  },
  {
    label: i18n.t('Доставка'),
    route: { name: 'tradepoints$edit/delivery' }, // 13
  },
];

export default [
  {
    path: '/tradepoints',
    name: 'tradepoints$list',
    component: () => import('@/apps/trade-points/views/List/List.vue'),
    redirect: { name: 'tradepoints$list/table' },
    meta: {
      breadCrumb: [breadCrumb[0]],
      title: i18n.t('Торговые точки'),
    },
    children: [
      {
        path: '/tradepoints/list',
        name: 'tradepoints$list/table',
        component: () => import('@/apps/trade-points/views/List/tabs/Table/Table.vue'),
      },
      {
        path: '/tradepoints/map',
        name: 'tradepoints$list/map',
        component: () => import('@/apps/trade-points/views/List/tabs/Map/Map.vue'),
      },
    ],
  },
  {
    path: '/tradepoints/:id',
    name: 'tradepoints$detail',
    component: () => import('@/apps/trade-points/views/Detail/Detail'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
      title: i18n.t('Торговая точка'),
    },
  },
  {
    path: '/tradepoints/:id/edit',
    name: 'tradepoints$edit',
    component: () => import('@/apps/trade-points/views/Edit/Edit'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[2]],
      title: i18n.t('Редактирование торговой точки'),
    },
    children: [
      {
        path: '/tradepoints/:id/edit/requisites',
        name: 'tradepoints$edit/requisites',
        component: () => import('@/apps/trade-points/views/Edit/components/Requisites/Requisites.vue'),
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[3]],
        },
      },
      {
        path: '/tradepoints/:id/edit/map',
        name: 'tradepoints$edit/map',
        component: () => import('@/apps/trade-points/views/Edit/components/Map/Map.vue'),
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[5]],
        },
      },
      {
        path: '/tradepoints/:id/edit/delivery-map',
        name: 'tradepoints$edit/delivery',
        component: () => import('@/apps/trade-points/views/Edit/components/DeliveryMap/DeliveryMap.vue'),
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[13]],
        },
      },
      {
        path: '/tradepoints/:id/edit/module',
        name: 'tradepoints$edit/module',
        component: () => import('@/apps/trade-points/views/Edit/components/ModuleBind/ModuleBind.vue'),
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[4]],
        },
      },
      {
        path: '/tradepoints/:id/edit/catalog-bind',
        name: 'tradepoints$edit/catalog-bind',
        component: () => import('@/apps/trade-points/views/Edit/components/CatalogBind/CatalogBind'),
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[7]],
        },
      },
      {
        path: '/tradepoints/:id/edit/integration',
        name: 'tradepoints$edit/integration',
        component: () => import('@/apps/trade-points/views/Edit/components/IntegrationScreen/IntegrationScreen.vue'),
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[8]],
        },
      },
      {
        path: '/tradepoints/:id/edit/accounting-page',
        name: 'tradepoints$edit/accounting-page',
        component: () =>
          import('@/apps/trade-points/views/Edit/components/IntegrationScreen/AccountingPage/AccountingPage.vue'),
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[9]],
        },
      },
      {
        path: '/tradepoints/:id/edit/catalog-create',
        name: 'tradepoints$edit/catalog-create',
        component: () => import('@/apps/trade-points/views/Edit/components/CatalogCreate/CatalogCreate'),
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[10]],
        },
      },
      {
        path: '/tradepoints/:id/edit/new-catalog',
        name: 'tradepoints$edit/new-catalog',
        component: () => import('@/apps/trade-points/views/NewCatalog/NewCatalog'),
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[11]],
        },
      },
      {
        path: '/tradepoints/:id/edit/new-item',
        name: 'tradepoints$edit/new-item',
        component: () => import('@/apps/items/views/ItemCreate/ItemCreate'),
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[12]],
        },
      },
      {
        path: '/tradepoints/:id/edit/detail-edit',
        name: 'tradepoints$edit/detail-edit',
        component: () => import('@/apps/trade-points/views/Edit/components/Detail/Detail'),
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[2]],
        },
      },
    ],
  },
  {
    path: '/tradepoints/create',
    name: 'tradepoints$create',
    component: () => import('@/apps/trade-points/views/Create/Create'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[6]],
      title: i18n.t('Создание торговой точки'),
    },
  },
  {
    path: '/tradepoints/bind-coffee-serial',
    name: 'tradepoints$bind-coffee-serial',
    component: () => import('@/apps/trade-points/views/Create/components/CoffeeMachineSerial/CoffeeMachineSerial'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[6]],
      title: i18n.t('Новая торговая точка'),
    },
  },
  {
    path: '/tradepoints/bind-jettino-serial',
    name: 'tradepoints$bind-jettino-serial',
    component: () => import('@/apps/trade-points/views/Create/components/JettinoSerial/JettinoSerial'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[6]],
      title: i18n.t('Новая торговая точка'),
    },
  },
];
