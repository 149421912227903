<template>
  <div :class="['default-template', `d-${displayMode}`]">
    <slot name="sidebar" v-if="!layoutsAreHidden"></slot>
    <div class="header-container" v-if="!layoutsAreHidden">
      <slot name="header"></slot>
    </div>
    <!-- { 'full-page': !layoutsAreHidden } in class below -->
    <div :class="['content-container', { 'tv-mode': isTv }]">
      <slot name="content"></slot>
    </div>
    <div ref="containerFooter" :class="['footer-container', contentBtmPadding === 16 && 'hide']" v-if="!layoutsAreHidden">
      <div id="teleport-footer" ref="teleportFooter"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    displayMode: String,
  },
  data() {
    return {
      layoutsAreHidden: false,
      contentBtmPadding: 0,
    };
  },
  computed: {
    ...mapGetters({
      isTv: 'core$main/isTv',
    }),
  },
  methods: {
    toggleLayouts(value) {
      this.layoutsAreHidden = value;
    },
    checkFooterExistence() {
      const teleportFooter = this.$refs.teleportFooter;
      const footerExists = !!teleportFooter?.children?.length;
      if (!footerExists) return (this.contentBtmPadding = 16);
      const containerFooter = this.$refs.containerFooter;
      const height = window.getComputedStyle(containerFooter).height;
      this.contentBtmPadding = parseInt(height);
    },
  },
  mounted() {
    this._timer = setInterval(this.checkFooterExistence.bind(this), 300);
  },
  beforeUnmount() {
    clearInterval(this._timer);
    this._timer = null;
  },
};
</script>

<style lang="scss" scoped src="./DefaultTemplated.scss"></style>
