// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('Поставки'),
    route: { name: 'supply$supply' },
  },
  {
    label: i18n.t('Поставка :id'),
    route: { name: 'supply$item' },
  },
  {
    label: i18n.t('Новая поставка'),
    route: { name: 'supply$create' },
  },
  {
    label: i18n.t('Поставка :id'),
    route: { name: 'supply$edit' },
  },
  {
    label: i18n.t("Печать стикера и ценника"),
    route: {name: 'supply$item-sticker'}
  }
];

export default [
  {
    path: '/storage/supply',
    name: 'supply$supply',
    component: () => import('@/apps/supply/views/Supply'),
    meta: {
      breadCrumb: breadCrumb.slice(0, 1),
      title: i18n.t('Склад / Поставки'),
    },
  },
  {
    path: '/storage/supply/:id',
    name: 'supply$item',
    component: () => import('@/apps/supply/views/Detail/Detail'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
      title: i18n.t('Склад / Поставка'),
    },
  },
  {
    path: '/storage/supply/create',
    name: 'supply$create',
    component: () => import('@/apps/supply/views/Create/Create'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[2]],
      title: i18n.t('Создание поставки'),
    },
  },
  {
    path: '/storage/supply/:id/edit',
    name: 'supply$edit',
    component: () => import('@/apps/supply/views/Edit/Edit'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[3]],
      title: i18n.t('Редактирование поставки'),
    },
  },
  {
    path: '/storage/supply/:id/sticker-print/',
    name: 'storage$item-sticker',
    component: () => import('@/apps/items/views/ItemSticker/ItemSticker'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[3], breadCrumb[4]],
      title: i18n.t('Печать стикера'),
    },
  },
];
