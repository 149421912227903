// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

export default [
  {
    path: '/registration',
    name: 'registration',
    component: () => import('@/apps/registration/views/Registration/Registration'),
    meta: {
      title: i18n.t('Регистрация'),
    }
  },
];
