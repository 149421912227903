// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('Сотрудники'),
    route: { name: 'employees$employees' },
  },
  {
    label: '',
    route: { name: 'employees$employee' },
  },
  {
    label: i18n.t('Добавить сотрудника'),
    route: { name: 'employees$create' },
  },
];

export default [
  {
    path: '/employees',
    name: 'employees$employees',
    component: () => import('@/apps/employees/views/Employees'),
    meta: {
      breadCrumb: [breadCrumb[0]],
      title: i18n.t('Сотрудники'),
    },
  },
  {
    path: '/employees/:id',
    name: 'employees$employee',
    component: () => import('@/apps/employees/views/Employee/Employee'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
      title: i18n.t('Страница сотрудника'),
    },
  },
  {
    path: '/employees/create',
    name: 'employees$create',
    component: () => import('@/apps/employees/views/Employee/Create'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[2]],
      title: i18n.t('Добавить сотрудника'),
    },
  },
];
