// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('Каталог Briskly'),
    route: {name: 'devices$list'}
  },
]

export default [
  {
    path: '/devices/buy',
    name: 'devices$buy',
    component: () => import('@/apps/devices/views/Buy/Buy'),
    meta: {
      breadCrumb: [breadCrumb[0]],
      title: i18n.t('Купить устройство'),
    },
  },
]
