// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('Юридические лица'),
    route: { name: 'entities$list' },
  },
  {
    label: i18n.t('Юридическое лицо ID :id'),
    route: { name: 'entities$detail' },
  },
  {
    label: i18n.t('Новое юридическое лицо'),
    route: { name: 'entities$create' },
  },
];

export default [
  {
    path: '/entities',
    name: 'entities$list',
    component: () => import('@/apps/entities/views/List/List.vue'),
    meta: {
      breadCrumb: [breadCrumb[0]],
      title: i18n.t('Юридические лица'),
    },
  },
  {
    path: '/entities/:id',
    name: 'entities$detail',
    component: () => import('@/apps/entities/views/Detail/Detail.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
      title: i18n.t('Юридическое лицо'),
    },
  },
  {
    path: '/entities/:id/refill',
    name: 'entities$refill',
    component: () => import('@/apps/entities/views/Refill/Refill.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
      title: i18n.t('Юридическое лицо'),
    },
  },
  {
    path: '/entities/create',
    name: 'entities$create',
    component: () => import('@/apps/entities/views/Create/Create.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[2]],
      title: i18n.t('Новое юридическое лицо'),
    },
  },
];
