import { en1 } from './en1';
/*

cat translations.csv | ./scripts/generate_locale_dictionaries.php "ru1,en1"

for google table transation script

*/
export const en = {
  ...en1,
  briskly_requisites: 'Briskly requisites',
  main_data: 'Main information',
  unit_796: 'Piece',
  unit_657: 'Item',
  unit_111: 'Milliliter',
  unit_112: 'Liter',
  unit_163: 'Gram',
  unit_625: 'Sheet',
  unit_704: 'Set',
  unit_715: 'Pair',
  unit_166: 'Kilogram',
  unit_736: 'Roll',
  unit_778: 'Package',
  documents_tabs_docs: 'Documents',
  documents_tabs_acts: 'Acts',
  documents_tabs_bills: 'Bills',
  documents_tabs_payment: 'Payment',
  documents_requisites: 'Requisites',
  documents_type: 'Type',
  documents_added: 'Added',
  documents_is_signed: 'Signed',
  documents_document_fallback_name: 'No name',
  documents_document_fallback_type: 'No type',
  documents_document_fallback_id: 'No id',
  documents_document_fallback_requisites: 'No requisites',
  Invoice: 'Invoice',
  UpdStatus1: 'Universal transfer document',
  ActForTheReportingPeriod1: 'Act for the reporting period',
  ActForTheReportingPeriod2: 'Settlement statement for the reporting period',
  OrdersReport1: 'Sales report',
  BYN: 'Belarusian ruble (BYN)',
  USD: 'US dollar (USD)',
  EUR: 'Euro (EUR)',
  KZT: 'Kazakh tenge (KZT)',
  CAD: 'Canadian dollar (CAD)',
  CNY: 'Chinese yuan (CNY)',
  RUB: 'Russian ruble (RUB)',
  TRY: 'Turkish lira (TRY)',
  UAH: 'Ukrainian hryvnia (UAH)',
  GBP: 'Pound Sterling (GBP)',
  CHF: 'Swiss franc (CHF)',
  HRK: 'Croatian kuna (HRK)',
  AED: 'Emirati dirham (AED)',

  BYN_CODE: '₽',
  USD_CODE: '$',
  EUR_CODE: '€',
  KZT_CODE: '₸',
  CAD_CODE: '$',
  CNY_CODE: '¥',
  RUB_CODE: '₽',
  TRY_CODE: '₺',
  UAH_CODE: '₴',
  GBP_CODE: '£',
  CHF_CODE: '₣',
  HRK_CODE: 'kn',
  AED_CODE: 'DH',

  WEEKDAY_01: 'Monday',
  WEEKDAY_02: 'Tuesday',
  WEEKDAY_03: 'Wednesday',
  WEEKDAY_04: 'Thursday',
  WEEKDAY_05: 'Friday',
  WEEKDAY_06: 'Saturday',
  WEEKDAY_07: 'Sunday',

  datetimeCreatedAt: 'Creation date and time',
  customer: 'Customer',
  status: 'Status',
  store: 'Supermarket',
  refrigerator: 'Micromarket',
  store_online: 'Online store',
  cafe: 'Pre-order',
  production_with_delivery: 'Production with delivery',
  filling_station: 'Gas station',
  cigarettes: 'Cigarettes',
  coffee_machine: 'Coffee machine',
  address: 'Address',
  phone: 'Phone',
  active_status: 'Awaiting payment',
  paid_status: 'Paid',
  refund_status: 'Returned',
  refund_awaiting_status: 'Return expected',
  refund_error_status: 'Return error',
  refund_partial_status: 'Partially refunded',
  removed_status: 'Removed',
  avg_amount: 'Average bill',
  income: 'Income',
  quantity: 'Number of purchases',
  total_amount: 'Revenue',
  extended_type: 'Type',
  extended_types_store: 'Store',
  extended_types_refrigerator: 'Micromarket',
  extended_types_store_online: 'Online store',
  extended_types_cafe: 'Cafe',
  extended_types_production_with_delivery: 'Delivery',
  extended_types_filling_station: 'Gas station',
  extended_types_cigarettes: 'Cigarettes',
  extended_types_coffee_machine: 'Coffee machine',
  extended_types_module: 'Module',
  store_count: 'Attached stores',
  complete_registration: 'To start using your Personal Account please sign up.',
  your_company_country: 'Country of your company',
  register: 'Sign up',
  your_company_inn: 'TIN of your company',
  your_company_number: 'Your company registration number',
  requisites_status_pending: 'Checked',
  requisites_status_active: 'Active',
  requisites_status_uncompleted: 'Not filled',
  completed: 'Completed',
  opened: 'Opened',
  import_excel_file: 'Upload .xlsx file',
  one_s: '1C',
  iiko: 'Айко',
  iiko_biz: 'Айко Биз',
  yml: 'YML',
  moy_sklad: 'Мой Склад',
  supermag: 'Супермаг',
  mts_kassa: 'МТС Касса',
  store_house: 'StoreHouse',
  poster: 'Poster',
  ya_badge: 'Яндекс.Бейдж',
  article: 'Vendor code',
  name: 'Name',
  price: 'Price',
  barcode: 'Barcode',
  unit_id: 'ОКЕИ (Ед. измерения)',
  volume: 'Volume',
  category: 'Category',
  catalog_name: 'Catalog',
  Success_notification: 'Success!',
  Error_notification: 'Error!',
  temperature: 'Temperature change notifications',
  connection: 'Connection status notifications',
  device_type_camera: 'Camera',
  device_type_coffee_machine: 'Coffee machine',
  device_type_microwave_oven: 'Microwave',
  device_type_module: 'Module',
  device_type_terminal: 'Terminal',
  tax_system_envd: 'ЕНВД',
  tax_system_esn: 'ЕСХН',
  tax_system_npd: 'НПД',
  tax_system_general: 'ОСН',
  tax_system_patent: 'Patent',
  tax_system_simplified_1: 'УСН (доходы ставка 6%)',
  tax_system_simplified_2: 'УСН (доходы - расходы ставка 15%)',
  role_admin: 'Administrator',
  role_checker: 'Checker',
  role_courier: 'Courier',
  roles: 'Roles',
  error_1000: 'Incorrect link',

  error_1010: 'Incorrect link',
  error_1020: 'Incorrect link',
  error_1030: 'Incorrect link',
  error_1040: 'Incorrect link',
  error_1050: 'Incorrect link',
  components_list: 'List',

  /* reports translation */
  report_type_order_stats: 'Order statistics',
  report_type_sale_stats: 'Sale statistics',
  report_type_item_stats: 'Item statistics',
  report_type_customer_stats: 'Customer statistics',
  report_type_sales_report: 'Sales report',
  report_type_promocodes_report: 'Promocodes report',
  report_type_deliveries_report: 'Deliveries report',
  reports_columns_item_price: 'Item price',
  Отчёт: 'Report',
  'Параметры отчёта': 'Report parameters',
  'Скачать .xlsx': 'Download .xlsx',
  'Скачать .csv': 'Download .csv',
  'Всего:': 'Total:',
  Свернуть: 'Hide',
  Развернуть: 'Show',
  'Чтобы установить параметры сначала выберите тип отчёта.': 'To set the parameters, first select the type of report.',
  'Для формирования отчёта необходимо выбрать тип и задать параметры.':
    'To generate a report, you must select the type and set the parameters.',
  'Сформировать отчёт': 'Generate report',
  tradePoint_status_active: 'Active',
  filters: 'Filters',
  unit: 'unit',

  undefined_CODE: '',
  ' шт': ' шт',

  reports_columns_amount: 'Amount with discount',
  reports_columns_base_amount: 'Sum',
  reports_columns_commission: 'Commission',
  reports_columns_count: 'Quantity',
  reports_columns_customer_count: 'Number of clients',
  reports_columns_customers_count: 'Number of clients',
  reports_columns_customer_id: 'Client ID',
  reports_columns_delivery_price_to_pay: 'Delivery price to pay',
  reports_columns_delivery_price: 'Delivery price',
  reports_columns_delivery_progress_status: 'Delivery status',
  reports_columns_external_service: 'Delivery type',
  reports_columns_hash_id: 'Order №',
  reports_columns_income: 'Income',
  reports_columns_item_barcode: 'Item barcode',
  reports_columns_barcode: 'Item barcode',
  reports_columns_item_count: 'Item quantity',
  reports_columns_item_hash_id: 'Item ID',
  reports_columns_hash: 'Item ID',
  reports_columns_item_name: 'Item name',
  reports_columns_payment_service: 'Payment type',
  reports_columns_period: 'Day',
  reports_columns_promo_code: 'Promo code',
  reports_columns_quantity: 'Item quantity',
  reports_columns_refund_amount: 'Refund',
  reports_columns_status_refund: 'Refund status',
  reports_columns_status: 'Order status',
  reports_columns_store_id: 'Store ID',
  reports_columns_timestamp_inserting: 'Order time',
  reports_columns_total_amount: 'Total amount',
  reports_columns_total_base_amount: 'Base amount',
  reports_columns_price: 'Price',
  reports_columns_sum_amount: 'Sales',
  reports_columns_order_id: 'Order ID',
  reports_columns_item_orders: 'Orders',
  reports_columns_id: 'ID',
  total_name: 'Total',
  'Для дальнейшего использования реквизиты должны быть верно заполнены и проверены нашим специалистом. Вы можете':
    'For further use requisites should be correctly filled and checked by our specialist. You can',
  'недостающие данные или вернуться к этому позже.': 'and come back later.',
  'Система налогообложения': 'Taxation system',
  'КПП:': 'KPP:',
  'ОГРН:': 'OGRN:',
  'ОКОПФ:': 'OKOPF:',
  'ОКВЭД:': 'OKVED:',
  'Здесь будет информация о перемещении ваших товаров из одной торговой точки в другую. Чтобы изменить область видимости товара,создайте перемещение':
    'Here will be information about the movement of your goods from one sales point to another. To change the product visibility area, create a move',
  'Подписать документ': 'Sign document',
  'Кол-во:': 'Quantity:',
  'Данные с выбранными параметрами не найдены': 'No data with chosen parameters',
  'Измените настройки фильтра': 'Please, change filter settings',
  'Поиск по таблице': 'Search...',
  'Кол-во': 'Quantity',
  'Добавить адрес': 'Add address',
  'Прикрепите устройства для новой торговой точки': 'Bind device for new store',
  Например: 'For example',
  'Briskly модуль': 'Briskly module',
  'поможет вам со статистикой': 'will help with statistics',
  options_grouping_DAYS: 'By days',
  options_grouping_WEEKS: 'By weeks',
  options_grouping_DAYS_OF_WEEK_AND_HOURS: 'By days of week and hours',
  options_grouping_DAYS_OF_WEEK: 'By days of week',
  options_grouping_HOURS: 'By hours',
  options_grouping_MONTHS: 'By months',
  options_grouping_YEARS: 'By years',
  options_grouping_NONE: 'None',
  options_payment_service_APP: 'Via app',
  options_payment_service_TERMINAL: 'Via terminal',
  options_payment_service_YANDEX_BADGE: 'Via Yandex Badge',
  options_type_BASE: 'Base',
  options_type_INCREASE: 'Increase',
  options_type_DECREASE: 'Decrease',
  options_status_PAID: 'Paid',
  options_status_PROCESSED: 'Processed',
  options_status_REFUND: 'Refund',
  options_status_REFUND_AWAITING: 'Refund processed',
  options_status_REFUND_ERROR: 'Refund error',
  options_status_REFUND_PARTIAL: 'Partial refund',
  options_status_ACTIVE: 'Active',
  options_status_REMOVED: 'Removed',
  Отсутствует: 'None',
  'По всем': 'All',
  'Все клиенты': 'All clients',
  Активный: 'Active',
  Неактивный: 'Inactive',
  'Перемещение №': 'Movement #',
  'В данном разделе вы сможете формировать различные отчёты по движению товаров с заданными параметрами, а так же скачать полученные данные в форматах .csv и .xlsx':
    'Here you can generate various item movement reports with chosen parameters and download received data in .csv and .xlsx format',
  'Отчёты по движению отсутствуют': 'No movement report',
  'Вы можете привязать каталог к нескольким торговым точкам, для этого воспользуйтесь “+” для добавления дополнительного поля выбора торговой точки':
    'You can bind catalog to multiple stores by clicking "+" button for adding extra store choosing field',
  'По значению этого префикса система распознаёт весовой товар с выделением цифрового кода товара и его веса в граммах':
    'System will identify weight item by highlighting the digital code and its weight in grams by using this prefix',
  'Доступные префиксы — от 20 до 29': 'Availible prefixes are from 20 to 29',
  'Если префикс не указан, то товары будут обработаны, как штучные': 'If there is no prefix, items will be considered as singles',
  'В данном разделе будут отображены все скидки на товары и категории товаров, настроенные при их создании и редактировании':
    'There will be all discounts for items and item categories configurated during their creation and editing',
  'Вы сможете настроить уже созданную ранее скидку или ': 'You can configure already created discount or ',
  'и прикрепить её к тому или иному товару или категории товаров.': 'and bind it to any item or item category.',
  'Так же вы можете': 'Also you can',
  ', это комплекс скидок, который вы можете применить ко всему заказу целиком в одной или нескольких торговых точках по заданным параметрам.':
    ", it's a number of discounts which you can bind to whole order or multiple stores by chosen parameters.",
  'При регистрации в систему Briskly Business, происходит автоматическое создание сотрудника с указанным вами номером телефона и присваивается роль Администратор, что даёт вам полный доступ ко всему функционалу Личного кабинета':
    'After signing up in Briskly business system, an employee with selected phone number is automatically created with the role of Administrator which gives access to full functionality of the whole service',
  'В данном разделе вы можете создавать и удалять сотрудников, редактировть их данные, присваивать им роли в соответствии с их обязанностями и закреплять за теми или иными торговыми точками':
    'There you can create and delete employees, edit their data, give them roles according to their position and bind them to differens stores',
  'Как только в какой то из ваших торговых точек будет создана первая оплата, вся информация о ней(состав, стоимость, статус и т.д.) будет размещена в данном разделе':
    'Whenever a new payment will be crated in any of the stores, all its info will be displayed here',
  'Вы сможете просмотреть информацию отфильтровав по: дате и времени создания оплаты, ее статусу и другим параметрам':
    'You can view info here by filtering by payment creation date and time, its status and other parameters',
  'Для торговых точек типа микромаркет доступен просмотр видео к оплатам':
    'For stores with micromarket type payment video capture is availible.',
  'Также в данном разделе Вы можете осуществлять возврат денежных средств клиенту в случае необходимости':
    'Also, ypu can do refunds if necessary',
  'Доступна возможность открывать микромаркет, менять товар, делать фото, загружать фото в интерфейс курьера.':
    "Availible to open micromarkets, change items, take and upload photos into courier's interface.",
  'Минимальный размер и формат': 'Minimal size and format',
  товары: 'Items',
  ' шт.': ' pcs.',
  январь: 'January',
  февраль: 'February',
  март: 'March',
  апрель: 'April',
  май: 'May',
  июнь: 'June',
  июль: 'July',
  август: 'August',
  сентябрь: 'September',
  октябрь: 'October',
  ноябрь: 'November',
  декабрь: 'December',
  'После сохранения данные вашего виртуального терминала будут отправлены на проверку':
    'After saving your terminals data will be verified',
  'После сохранения данные вашего виртуального терминала будут отправлены на проверку.':
    'After saving your terminals data will be verified.',
  'Информация об изменении статуса нового виртуального терминала будет отправлена в push-уведомлении, а также отобразится в карточке на странице':
    'Status change information will be sent in push notification and also will be displayed in a card on the page',
  Сотрудники: 'Employees',
  'Уставный капитал:': 'Authorized capital:',
  'ИНН:': 'INN',
  'Тел:': 'Tel:',
  'Р/с №:': 'R/s #:',
  'БИК:': 'BIK:',
  'Деактивировать торговую точку': 'Deactivate store',
  'Введите кол-во...': 'Enter quantity...',
  Распечатать: 'Print',
  'Внимание!': '',
  'Вы уверены, что хотите покинуть страницу? Все несохранённые данные будут утеряны.': '',
  'Кол-во наклеек': '# of stickers',
  'Будет сгенерирован при сохранении...': 'Will be generated after save...',
  'Внешний id': 'External id',
  'В настройках торговой точки вы можете настроить расписание работы, включить/отключить уведомления, а также назначить сотрудников.':
    'You can configure schedule, turn notifications on and off and assign employees in store settings.',
  'Ввести идентификатор': 'Enter id',
  'Данный номер телефона будет использован в чеках оплаты приложения B-Pay.': 'This phone number will be used in B-Pay app receipts.',
  'Минимальная длина': 'Minimum length is',
  'Максимальная длина': 'Maximum length is',
  символов: 'symbols',
  'Присутствуют недопустимые символы': 'Input contains unacceptable symbols',
  'Обязательное поле': 'Required field',
  'Не валидный телефон': 'Invalid number',
  'Не валидный email': 'Invalid email',
  'Не валидная дата': 'Invalid date',
  'История обновлений': 'Update history',
  'Внимание! Категория должна быть привязана к какому либо каталогу': 'Attention! Category must be bound to a catalog',
  'Категория может быть прикреплена только к одному каталогу': 'A category can be bound to only one catalog',
  'Создайте новую категорию  и прикрепите её к каталогу': 'Create a new category and bind it to a catalog',
  'Вы также можете пропустить этот шаг чтобы вернуться к нему позже из экрана каталога':
    'You can skip this step to return to it later from catalog screen',
  'Вы собираетесь удалить категорию Внимание! Удаление категории повлечет удаление всех товаров в этой категории':
    'You are going to delete a category Attention! Deleting category will result in all category items deletion',
  'Вы уверены, что хотите покинуть страницу ? Все несохранённые данные будут утеряны.':
    'Are you sure you want to leave this page? All unsaved data will be lost',
  'Столбцы «Название», «Цена» и «Штрихкод» являются обязательными.': '"Name", "Price" and "Barcode" columns are required.',
  'Столбцы «Название», «Цена» и «Штрихкод» являются обязательными': '"Name", "Price" and "Barcode" columns are required',
  'Вы уверены что хотите убрать товар из категории?': 'Are you sure you want to remove item from category?',
  'Прикреплённые торговые точки': 'Bound stores',
  'Прикреплённые категории': 'Bound categories',
  'Все каталоги': 'All catalogs',
  'Для формирования отчёта по движению товаров необходимо задать следующие параметры: период времени, выбрать одну  или несколько торговых точек и  товар(ы), движение по которым вас интересует':
    'To generate a report on the movement of goods, you must set the following parameters: time period, select one or more outlets and product(s), the movement of which you are interested.',
  'Загрузить .xlsx файл': 'Upload an .xlsx file',
  'По значению этого префикса система распознаёт весовой товар с выделением цифрового кода товара и его веса в граммах. Доступные префиксы — от 20 до 29. Если префикс не указан, то товары будут обработаны, как штучные.':
    'System will identify weight item by highlighting the digital code and its weight in grams by using this prefix. Availible prefixes are from 20 to 29. If there is no prefix, items will be considered as singles',
  Неизвестно: 'Unknown',
  'Устройство не найдено. Проверьте корректность введенного кода и повторите попытку':
    'Device not found. Check whether the entered code is correct and try again',
  'Выберите один или несколько каталогов из списка, чтобы привязать их к складу вашей торговой точки. Либо':
    "Choose one ore more catalogs from the list to bind them to your store's warehouse, or",
  'и заполните его товарами.': 'and fill it with items.',
  'Выберите формат в котором будет экспортирован список отчетов': 'Choose format for exporting reports list',
  'Выберите формат в котором будет экспортирован список товаров': 'Choose format for exporting items list',
  'Выберите формат в котором будет экспортирован список оплат': 'Choose format for exporting payments list',
  'Возрастное ограничение категории 18+': '18+ age limit',
  'Новая категория': 'New category',
  'Перенести в категорию': 'Move to a category',
  'Перенести в каталог': 'Move to a catalog',
  'Экспортировать оплату': 'Export payment',
  'Стикер товара': 'Item sticker',
  'Всего товаров к возврату: ': 'Total items to return:',
  'Всего товаров к возврату ': 'Total items to return',
  списание: 'write-off',
  списания: 'write-offs',
  Экспортировать: 'Export',
  'Экспортировать торговую точку': 'Export store',
  'Удалить торговую точку? Это приведет к безвозвратному удалению':
    'Are you sure you want to delete a store? This will result in permanent deletion',
  'Удалить каталог': 'Delete catalog',
  'Вы собираетесь удалить каталог! Для удаления каталога необходимо привязать новый каталог ко всем торговым точкам взамен удаляемого.':
    'You are about to delete a catalog! To delete a catalog, you must bind a new catalog to all outlets in place of the one you are deleting.',
  'Экспортировать каталог': 'Export catalog',
  'Вы можете добавить сразу несколько категорий в один каталог, для этого воспользуйтесь “+” для добавления  дополнительного поля для ввода названия новой категории.':
    'You can add multiple categories in one catalog at once, use "+" for adding extra field for new category name.',
  'Данные сохранены.': 'Data has been saved',
  'Добавить товары в категорию?': 'Add items to a category?',
  'Категория будет создана, но нужно заполнить её товарами': 'Category will be created, but it has to be filled with items',
  'Добавить товар вручную': 'Add item by hand',
  'Прикрепить к торговым точкам': 'Bind to stores',
  'Категория успешно прикреплена.': 'Category successfully bound.',
  'Категория успешно обновлена': 'Category successfylly updated',
  'Категория обновлена': 'Category updated',
  'Категория успешно создана.': 'Category successfully created.',
  'Категория создана': 'Category created',
  'Категория успешно удалена.': 'Category successfully deleted.',
  'Категория удалена': 'Category deleted',
  'Успешно!': 'Success!',
  'успешно привязано к торговой точке': 'has been successfully linked to the point of sale',
  'Каталог не был обновлен.': 'The catalog was not updated.',
  'Каталог успешно обновлен.': 'The catalog has been successfully updated.',
  'Каталог успешно создан.': 'Catalog successfully created.',
  'Каталог обновлен': 'The catalog has been updated',
  'Каталог создан': 'Catalog created',
  'Торговые точки не были обновлены.': 'Trade points were not updated',
  'Торговые точки обновлены.': 'Trade points have been updated.',
  'Торговые точки успешно обновлены.': 'Trade points have been successfully updated.',
  'Торговые точки успешно прикреплены к каталогу.': 'Stores successfully bound to catalog',
  'Торговые точки прикреплены': 'Stores bound',
  'Профиль успешно изменен!': 'Profile successfully updated',
  'Файл успешно загружен!': 'File successfully uploaded',
  'Загрузка отменена': 'Upload cancelled',
  'Сохранение успешно завершено!': 'Saved successfully',
  Успех: 'Success',
  'Успех!': 'Success!',
  Внимание: 'Caution',
  'Создание скидки завершено!': 'Discount save finished',
  Документ: 'Document',
  'был успешно подписан!': 'was successfully signed!',
  'Документ успешно подписан!': 'Document signed successfully',
  'Реквизиты успешно добавлены': 'Requisites added successfully',
  'Загрузите фото!': 'Upload a photo!',
  'Вы не можете создать категорию без выбранного каталога!': 'You cannot create a category without a chosen catalog',
  'Вы не можете добавить больше 7-ми сопутствующих товаров': 'You cannot add more than 7 set items',
  'Товар создан!': 'Item created',
  'Меню создано!': 'The menu has been created!',
  'Меню удалено': 'Menu deleted',
  'Меню не было привязано': 'The menu wasn\'t attached',
  'Меню не было удалено': 'The menu was not deleted',
  'Данное фото уже было загружено!': 'This photo has already been uploaded',
  'Необходимо указать дату и время изготовления': 'Creation date and time is required',
  'Торговая точка успешно деактивирована': 'Store deactivated',
  'Торговая точка успешно активирована': 'Store activated',
  'Порядок напитков успешно изменен': 'Beverage list successfully changed',
  'Основные данные успешно сохранены!': 'Main data successfully saved!',
  'Настройки уведомлений успешно сохранены!': 'Notification settings saved successfully',
  'Настройки расписания успешно сохранены!': 'Schedule settings saved successfully',
  Создание: 'Creation',
  'успешно завершено': 'completed successfully',
  Редактирование: 'Editing',
  'Не выбран каталог в торговой точке!': 'No selected catalog in store!',
  'Ошибка при создании': 'Error in creating',
  'Выберите формат в котором будет': 'Select a format in which',
  ' экспортировано перемещение': ' will the movement be exported',
  ' экспортирован список перемещений': 'will the movement list be exported',
  'Экспорт перемещения': 'Movement export',
  Прикрепить: 'Bind',
  'Привязать к торговой точке': 'Bind to a store',
  'SWIFT / БИК:': 'SWIFT / BIK',
  'SWIFT / БИК': 'SWIFT / BIK',
  'Не заполнен': 'Not filled in',
  'Наименование банка': 'Bank name',
  'Наименование банка:': 'Bank name:',
  'Для активации торговой точки необходимо заполнить все данные в выбранных реквизитах. Вы можете':
    'In order to activate a store you should fill inn all fields in the chose requisites. You can ',
  'сделать это сейчас': 'do it now',
  'или позже из раздела': 'or later from',
  'Видео отсутствует': 'No video',
  'Сумма возврата': 'Refund amount',
  'Вы действительно хотите удалить товар?': 'Are you sure you want to delete item?',
  'Экспортировать товар': 'Export item',
  'Добавить товары': 'Add items',
  '№ Транзакции': 'Transaction #',
  '№ расчётного счёта': 'Current payment account #',
  'ID клиента': 'Client ID',
  'URL-адрес сайта': 'Site URL',
  ОСН: 'OSN',
  'УСН (доходы, ставка 6%)': 'USN (income, rate 6%)',
  'УСН (доходы - расходы, ставка 15%)': 'USN (income - expenses, rate 15%)',
  ЕНВД: 'UTII',
  ЕСХН: 'CAP',
  Патент: 'Patent',
  'Платёжная информация': 'Payment information',
  'После заполнения и сохранения недостающих данных, ваши реквизиты автоматически будут отправлены на проверку. Информация об изменении статуса новых реквизитов будет отправлена в push-уведомлении, а также отобразится в карточке на странице':
    'After entering and saving all missing data your requisites will be automatically sent for checking. Information about status change will be sent ina push message and will be displayed in a card on th page',
  'Доставка партнёрами Briskly': 'Delivery by Briskly partners',
  'Клиент сам заберёт заказ по указанному адресу': 'Client will get order in the specified address themself',
  'Печатать дополнительное описание товара': 'Print additional item description',
  'В этом разделе появятся акты, которые вы заключили с вашими контрагентами. ':
    'There will be acts which you have concluded with your counterparties. ',
  'Половину вверх': 'Half up',
  'Не подписан': 'Not signed',
  'Половину вниз': 'Half down',
  'Целиком вверх': 'Ceil',
  'Целиком вниз': 'Floor',
  'Если вам необходимо создать новый каталог перейдите по ссылке ': 'If you need to create a new catalog, follow the link: ',
  'Загрузить .xlsx': 'Upload .xlsx',
  'загрузить .xlsx файл': 'upload an .xlsx file',
  'сделать это вручную': 'do it by hand',
  'Настройте интеграцию со своей системой товароучёта и автоматически заполните склад товарами.':
    'Set up integration with your billing system and automatically fill your warehouse with goods.',
  'Фактический адрес:': 'Actual address',
  'За указанный период нет данных.Измените параметры отчета.': 'No data for selected period. Please, change report settings.',
  'Для корректного заполнения всех полей может потребоваться пригласить вашего технического специалиста':
    'Attention! For correct filling of all fields you may need to invite your technical specialist.',
  'Распечатать стикеры': 'Print stickers',
  'Экспорт всех товаров': 'Export all items',
  'По данному заказу ещё не было возвратов': 'There have been no returns for this order yet.',
  'Тип события': 'Event type',
  'Показать события': 'Show events',
  'Отчёт о событиях': 'Events report',
  'Чтобы отобразить события выберите нужные значения.': 'Choose needed values to show events.',
  'Движения товаров в корзинах': 'Item movements in carts',
  'Название отчёта': 'Report name',
  'Введите название вашего отчёта': 'Enter your report name',
  'Экспортировать отчет': 'Export report',
  'Вы действительно хотите удалить отчет?': 'Are you sure you want to delete this report?',
  'Вы можете прикрепить каталог к нескольким торговым точкам. Для этого отметьте нужные торговые точки в выпадающем окне.':
    'You can bind catalog to multiple stores. To do this you need to select needed stores in the dropdown window.',
  'ID и название торговой точки': 'Store ID and name',
  'Создать категорию': 'Create a category',
  'Категория прикреплена': 'Category bound',
  'Торговая точка успешно добавлена!': 'Store successfully added!',
  'Код страны': 'Country code',
  'Не валидный номер': 'Invalid number',
  'Информация об изменении статуса виртуального терминала будет отправлена в push-уведомлении, а также отобразится в карточке на странице':
    'Virtual terminal status change info will be sent in push-notification and also will be displayed on page',
  'Виртуальные терминалы': 'Virtual terminals',
  Логин: 'Login',
  Пароль: 'Password',
  'Название типа цен': 'Price type name',
  'Отправлять проведенные заказы': 'Send finished orders',
  'Разрешить загрузку товаров без штрихкодов (не рекомендуется, не будет работать система учета штрихкодов в приложениях).':
    'Allow uploading items without barcodes (not recommended, barcode accounting system in apps will not work )',
  'Идентификатор типа цен': 'Price type identifier',
  События: 'Events',
  'Страница события': 'Event page',
  'Всего товаров к возврату': 'Total items to return',
  '№ оплаты': 'Payment #',
  'Экспортировать товары': 'Export items',
  'Детальный обзор': 'Detailed review',
  Событие: 'Event',
  Товар: 'Item',
  Итог: 'Total',
  'Если вам необходимо создать новый отчёт с небольшими изменениями, вы можете внести их в данном разделе и сформировать отчёт заново с обновлёнными параметрами':
    'If you need to create a new report with slight changes, you can enter them here and generate new report with new parameters',
  'Отчетный период': 'Reporting period',
  'Адрес не указан': 'No address specified',
  Тел: 'Phone',
  'Р/с №': 'Account #',
  перемещение: 'movement',
  перемещения: 'movements',
  'ID тор. точки': 'Store ID',
  'Вы можете добавить сразу несколько категорий в один каталог, для этого воспользуйтесь для добавления дополнительного поля для ввода названия новой категории':
    'You can add multiple categories to one catalog by using an extra field for new category enter',
  'ID торговой точки': 'Store ID',
  'Экспортировать торговую ': 'Export ',
  точку: 'store',
  точки: 'stores',
  'Заполнить сейчас': 'Fill in now',
  поставку: 'delivery',
  поставки: 'deliveries',
  'Сумма:': 'Amount:',
  event_store_id: 'Store ID',
  event_store_name: 'Store name',
  event_created_at: 'Creation time',
  event_status: 'Status',
  event_customer_id: 'Customer ID',
  event_create: 'Creation',
  event_delete_position: 'Delete position',
  event_increase_position: 'Increase position',
  event_decrease_position: 'Decrease position',
  event_create_position: 'Add item',
  event_delete: 'Delete item',
  event_refund: 'Refund',
  'Время события': 'Event time',
  Стоимость: 'Price',
  минут: 'minute',
  сек: 'sec',
  Длительность: 'Length',
  Увеличение: 'Zoom',
  а: '',
  ы: 's',
  '': 's',
  'Успешно удалено': 'Deleted successfully',
  'Категория успешно активирована': 'Category successfully activated',
  'Категория успешно деактивирована': 'Category successfully deactivated',
  'Успешно перемещен в каталог': 'Successfully moved to catalog',
  'Успешно привязан': 'Successfully bound',
  'Успешно перемещено': 'Successfully moved',
  'Успешно убран из категории': 'Successfully removed from category',
  'Успешно сохранено': 'Successfully saved',
  'Ссылка успешно скопирована!': 'Link successfully copied',
  Успешно: 'Success',
  'Категория не пуста': 'Category is not empty',
  'Категории не были созданы': 'Categories were not created',
  'Категории не были удалены.': 'Categories were not deleted',
  'Категории созданы и успешно прикреплены к каталогу.': 'Categories have been created and successfully attached to the catalog.',
  'Категории созданы и прикреплены': 'Categories have been created and attached',
  'Категории удалены': 'Categories have been deleted',
  'Категории успешно удалены.': 'Categories have been successfully deleted.',
  Ошибка: 'Error',
  'Что то пошло не так, попробуйте перезагрузить страницу': 'Something went wrong, please, try to reload page',
  not_found_text: 'Oops! Something went wrong...',
  back_to_home: 'Back to home',
  'Данный заказ не оплачен': 'This order is not yet paid for',
  'Обратите внимание, что клиент добавлял товары в корзину, но не перешел к оплате.':
    "Client added items to cart, but didn't proceed to payment.",
  'Подробную информацию о действиях клиента можно получить во вкладке «Видео» или разделе «События»':
    "You can get detailed info in 'Video' or 'Events' tab",
  'В Архиве': 'In archive',
  support_service_greeting: 'Hi! How can we help?',
  'Удалить скидку': 'Removing discount',
  'Вы действительно хотите удалить скидку на товар?':'Do you really want to remove the discount on the product?',
  'Вы действительно хотите удалить скидку на категорию?': 'Do you really want to remove a discount on a category?',
  'Вы действительно хотите удалить набор скидок?': 'Do you really want to remove the discount set?',
  'Помощь':'Help',
  'Вопросы и ответы':'Faq',

  week: 'Week',
  month: 'Month',
  three_months: '3 Months',
  six_months: '6 Months',

  sum_orders_amount: 'By profit',
  sum_orders_count: 'By orders',
  sum_items_count: 'By items',
  is_only_my_store: 'My markets',
  period: 'Range',
  main_rate: 'Main rate',
  region: 'Region',
  currency: 'Currency',
  vat_rate: 'VAT rate (%)',

  'Печатать текущую дату': 'Print current date',
  'Печатать наименование производителя': 'Print manufacturer\'s name',
  'Разместить на листе формата А4': 'Place on an A4 sheet',

};
