// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

import { NoticeEnumTypes } from '@/core/data/Notice-enum';
import { companyAPI } from '@/core/api/company';
import router from '@/core/router';

const state = {
  taxSystems: {},
  loadedBanks: [],
  loadedAddresses: [],
  loadedFieldsToComplete: null
};

const getters = {
  loadedFieldsToComplete: (state) => state.loadedFieldsToComplete,
  taxSystems: (state) => Object.entries(state.taxSystems).map(([value, name]) => ({ value, name })),
  loadedAddresses: (state) => state.loadedAddresses,
  loadedBanks: (state) => state.loadedBanks,
};

const actions = {
  getPartyFieldsToComplete: async ({ commit }, payload) => {
    try {
      const fields = await companyAPI.getFieldsToComplete({ party_id: payload });
      commit('_getPartyFieldsToComplete', fields);
    } catch (error) {
      console.error('store/getPartyFieldsToComplete:', error);
    }
  },
  getAddresses: async ({ commit }, payload) => {
    try {
      const addresses = await companyAPI.getAddress({ q: payload });
      commit('_getAddresses', addresses);
    } catch (error) {
      console.error('store/getAddresses:', error);
    }
  },
  getBanks: async ({ commit }, payload) => {
    try {
      const banks = await companyAPI.getBankData({ q: payload });
      commit('_getBanks', banks);
    } catch (error) {
      console.error('store/getBanks:', error);
    }
  },
  getTaxSystems: async ({ commit }) => {
    try {
      const taxSystems = await companyAPI.getAvailableTaxSystems();
      commit('_getTaxSystems', taxSystems);
    } catch (error) {
      console.error('store/getTaxSystems:', error);
    }
  },
  completeRefill: async ({ dispatch }, payload) => {
    try {
      if (Object.keys(payload.fields.party).length) {
        await companyAPI.patchParty({
          id: payload.ids.party,
          ...Object.fromEntries(Object.entries(payload.fields.party).filter(([_, v]) => v != ''))
        })
      }
      if (Object.keys(payload.fields.account).length) {
        await companyAPI.patchPartyAccount({
          id: payload.ids.account,
          ...Object.fromEntries(Object.entries(payload.fields.account).filter(([_, v]) => v != ''))
        })
      }
      if (Object.keys(payload.fields.doc2).length) {
        await companyAPI.patchPartyDoc2({
          id: payload.ids.doc2,
          date_format: 'd.m.Y',
          ...Object.fromEntries(Object.entries(payload.fields.doc2).filter(([_, v]) => v != ''))
        })
      }
      if (Object.keys(payload.fields.file).length && Object.values(payload.fields.file).length) {
        if (Object.entries(payload.fields.file).filter(([_, v]) => v != '').length) {
          const filesIds = await Promise.all(
            Object.entries(payload.fields.file).map(async (file) => {
              const contents = await companyAPI.uploadPartyFile({
                file: file[1],
                type: file[0],
                party_id: payload.ids.party
              });
              return contents.id;
            }),
          );
          if (Object.keys(payload.fields.doc2).length) {
            await companyAPI.bindPhotosToDoc2({
              file_ids: filesIds,
              doc2_id: payload.ids.doc2
            });
          }
        }
      }
      dispatch(
        'core$notifications/add',
        {
          message: i18n.t('Изменения успешно сохранены'),
          type: NoticeEnumTypes.success,
          source: '',
          title: i18n.t('Успешно!'),
        },
        { root: true },
      );
      router.push({ name: 'entities$detail', params: { id: payload.ids.party } });
    } catch (error) {
      dispatch(
        'core$notifications/add',
        { message: error, type: NoticeEnumTypes.error, source: 'completeRefill', title: 'Ошибка!' },
        { root: true },
      );
    }
  },
};

const mutations = {
  _getPartyFieldsToComplete: (state, payload) => (state.loadedFieldsToComplete = payload),
  _getAddresses: (state, payload) => (state.loadedAddresses = payload),
  _getBanks: (state, payload) => (state.loadedBanks = payload),
  _getTaxSystems: (state, payload) => (state.taxSystems = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
