// --- INJECTED ---
import {getI18n} from '@/core/i18n';

const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('Сервисный центр'),
  },
  {
    label: i18n.t('Документы'),
    route: {name: 'service-documents$list'},
  },
];

export default [
  {
    path: '/service-center/documents',
    name: 'service-documents$list',
    component: () => import('@/apps/service_center/documents/views/List/List.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
    },
  },
];
