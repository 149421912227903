const state = {
  isMapModalOpen: '',
};

const getters = {
  isModalOpen: (store) => store.isMapModalOpen,
};

const actions = {
  setModalOpen: async ({ commit }, payload) => {
    commit('_setModalOpen', payload);
  },
};

const mutations = {
  _setModalOpen: (state, payload) => {
    state.isMapModalOpen = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
