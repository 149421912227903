// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
    {
        label: i18n.t('Отчёты'),
        route: { name: 'reports$list' },
    },
];

export default [
    {
        path: '/reports',
        name: 'reports$list',
        component: () => import('@/apps/reports/views/List'),
        meta: {
            breadCrumb,
            title: i18n.t('Отчеты'),
        },
    },
];
