import { companyAPI } from '@/core/api/company';

const state = {
  employeePreferences: {
    catalogs_hidden_fields: [],
    categories_hidden_fields: [],
    discounts_hidden_fields: [],
    documents_hidden_fields: [],
    employees_hidden_fields: [],
    items_hidden_fields: [],
    parties_hidden_fields: [],
    push_distributions_hidden_fields: [],
    stores_hidden_fields: [],
    orders_hidden_fields: [],
    report_main_hidden_fields: [],
    report_promo_hidden_fields: [],
    report_delivery_hidden_fields: [],
    employee_timezone: '',
    page_size: 10,
    is_xmas_enabled: false,
    is_feature_flag: false,
  },
  companyId: null,
  loaded: false, // added for proper work of some tables FIXME
};

const getters = {
  employeePreferences: (state) => state.employeePreferences,
  companyId: (state) => state.companyId,
  catalogsHiddenFields: (state) => state.employeePreferences.catalogs_hidden_fields,
  categoriesHiddenFields: (state) => state.employeePreferences.categories_hidden_fields,
  discountsHiddenFields: (state) => state.employeePreferences.discounts_hidden_fields,
  documentsHiddenFields: (state) => state.employeePreferences.documents_hidden_fields,
  employeesHiddenFields: (state) => state.employeePreferences.employees_hidden_fields,
  itemsHiddenFields: (state) => state.employeePreferences.items_hidden_fields,
  partiesHiddenFields: (state) => state.employeePreferences.parties_hidden_fields,
  pushDistributionsHiddenFields: (state) => state.employeePreferences.push_distributions_hidden_fields,
  storesHiddenFields: (state) => state.employeePreferences.stores_hidden_fields,
  ordersHiddenFields: (state) => state.employeePreferences.orders_hidden_fields,
  reportMainHiddenFields: (state) => state.employeePreferences.report_main_hidden_fields,
  reportPromoHiddenFields: (state) => state.employeePreferences.report_promo_hidden_fields,
  reportDeliveryHiddenFields: (state) => state.employeePreferences.report_delivery_hidden_fields,
  employeeTimezone: (state) => state.employeePreferences.employee_timezone,
  pageSize: (state) => state.employeePreferences.page_size,
  isXmasEnabled: (state) => state.employeePreferences.is_xmas_enabled,
  isFeatureFlag: (state) => state.employeePreferences.is_feature_flag,
  preferencesLoaded: (state) => state.loaded,
};

const actions = {
  getEmployeePreferences: async ({ commit }) => {
    try {
      const { preferences, company_id: companyId } = await companyAPI.getEmployeePreferences();
      commit('_setPreferences', preferences);
      commit('_setCompanyId', companyId);
    } catch {
    }
  },

  setEmployeePreferences: async ({ state }, { key, preferences }) => {
    if (!key) return console.error('The `key` parameter is required!');

    await companyAPI.setEmployeePreferences({ key, preferences });
  },

  resetKey: async ({ state }, key) => {
    if (!key) return console.error('The `key` parameter is required!');

    await companyAPI.setEmployeePreferences({ key, preferences: [] });
  },
  setPageSize: async ({ commit }, size) => {
    await companyAPI.setEmployeePreferences({
      key: 'page_size',
      preferences: size,
    });
    commit('_setPageSize', size);
  },
};

const mutations = {
  _setPreferences: (state, preferences) => {
    state.employeePreferences = preferences;
    state.loaded = true;
  },
  _setCompanyId: (state, id) => {
    state.companyId = id;
  },
  _setPageSize: (state, size) => (state.employeePreferences.page_size = size),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
