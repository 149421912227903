import moment from "moment"
import store from "@/core/store"

export default {
    install: (app, options) => {
        const convertTimestamp = (timestamp) => {
            const timeZoneList = store.getters["core$main/timezonesRaw"]
            const employeeTimezone = store.getters["core$employeePreferences/employeeTimezone"]

            if (timeZoneList?.items) {
                let employeeTimezoneOffset = timeZoneList.items[employeeTimezone] * -1

                if (!employeeTimezoneOffset) {
                    employeeTimezoneOffset = 10800 // UTC+3 by default
                }

                return moment(timestamp).utc().add(employeeTimezoneOffset, 'seconds').format()
            }

            return timestamp
        }

        app.config.globalProperties.$adjustTz = (val) => {
            return convertTimestamp(val)
        }
    }
}