import ChannelHandler from './channel-handler';
import { ChannelConnector, bus, storage } from '@brskl/core';

let instance: ChannelHandler;

export function init(endpoint) {
  if (instance) return;

  instance = new ChannelHandler({ endpoint });

  new ChannelConnector(instance);
  bus.emit('core$auth/init');
}

function wait() {
  return new Promise((resolve) => {
    const timer = setInterval(() => {
      if (instance.channel) {
        resolve(null);
        clearInterval(timer);
      }
    }, 100);
  });
}

export async function get() {
  if (instance.channel) return instance.channel;
  await wait();

  return instance.channel;
}
