// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

import { companyAPI } from '@/core/api/company';

const state = {
  timestampValue: {
    start: '',
    end: '',
  },
  eventType: '',
  customerId: '',
  selectedStore: [],
  orderStatus: [],
  orderNumber: '',
};

const getters = {
  timestampValue: (state) => state.timestampValue,
  eventType: (state) => state.eventType,
  customerId: (state) => state.customerId,
  selectedStore: (state) => state.selectedStore,
  orderStatus: (state) => state.orderStatus,
  orderNumber: (state) => state.orderNumber,
};

const actions = {
  setValues: async ({ commit }, { timestampValue, eventType, customerId, selectedStore, orderStatus, orderNumber }) => {
    commit('_setTimestampValueStart', timestampValue?.start ? timestampValue.start : '');
    commit('_setTimestampValueEnd', timestampValue?.end ? timestampValue.end : '');
    commit('_setEventType', eventType ? eventType : '');
    commit('_setCustomerId', customerId ? customerId : []);
    commit('_setSelectedStore', selectedStore ? selectedStore : []);
    commit('_setOrderStatus', orderStatus ? orderStatus : []);
    commit('_setOrderNumber', orderNumber ? orderNumber : '');
  },
};

const mutations = {
  _setTimestampValueStart: (state, payload) => (state.timestampValue.start = payload),
  _setTimestampValueEnd: (state, payload) => (state.timestampValue.end = payload),
  _setEventType: (state, payload) => (state.eventType = payload),
  _setCustomerId: (state, payload) => (state.customerId = payload),
  _setSelectedStore: (state, payload) => (state.selectedStore = payload),
  _setOrderStatus: (state, payload) => (state.orderStatus = payload),
  _setOrderNumber: (state, payload) => (state.orderNumber = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
