import steps from '../views/Catalogs/data/stepList';


const state = {
  steps: steps(),
  primaryData: { name: '', currency_code: '', barcode_prefix_2: '' },
  tradePoints: [],
  categories: [],
};

const getters = {
  currentStep: (state) => state.steps.indexOf(state.steps.find((el) => el.isActive)),
  steps: (state) => state.steps,
  primaryData: (state) => state.primaryData,
  tradePoints: (state) => state.tradePoints,
  categories: (state) => state.categories,
};

const actions = {
  changeStep: async ({ commit }, index) => {
    commit('_changeStep', index);
  },
  completeStep: async ({ commit }, payload) => {
    commit('_completeStep', payload);
  },
  resetState: async ({ commit }) => {
    commit('_resetState');
  },
  setPrimaryData: async ({ commit, dispatch }, payload) => {
    commit('_setPrimaryData', payload);
  },
  setTradePoints: async ({ commit }, payload) => {
    commit('_setTradePoints', payload);
  },
  setCategories: async ({ commit }, payload) => {
    commit('_setCategories', payload);
  },
  addCategory: async ({ commit }, payload) => {
    commit('_addCategory', payload);
  },
  removeTradePoint: async ({ commit }, id) => {
    commit('_removeTradePoint', id);
  },
  removeCategory: async ({ commit }, id) => {
    commit('_removeCategory', id);
  },
  clearTradePoints: async ({ commit }) => {
    commit('_clearTradePoints');
  },
  clearPrimaryData: async ({ commit }) => {
    commit('_clearPrimaryData');
  },
  clearCategories: async ({ commit }) => {
    commit('_clearCategories');
  },
  clearSteps: async ({ commit }) => {
    commit('_clearSteps');
  },
  updateTradePoints: async ({ commit }, payload) => {
    commit('_updateTradePoints', payload);
  },

  // createEntity: async ({ state }) => {
  //   try {
  //     const createdParty = await companyAPI.createParty({ ...state.storeRequisites, type: state.storeRequisitesType });
  //     if (createdParty.type === 'individual') {
  //       const filesIds = await Promise.all(
  //         state.storeFiles.map(async (file) => {
  //           const contents = await companyAPI.uploadPartyFile({ ...file, party_id: createdParty.id });
  //           return contents.id;
  //         }),
  //       );
  //       await companyAPI.createPartyDoc({ ...state.storeDoc, party_id: createdParty.id, files_ids: filesIds });
  //     } else {
  //       await companyAPI.createPartyDoc({ ...state.storeDoc, party_id: createdParty.id });
  //     }
  //     await companyAPI.createPartyAccount({ ...state.storeAccount, party_id: createdParty.id });
  //     await companyAPI.sendPartyDoc({ id: createdParty.id });
  //     return createdParty;
  //   } catch (error) {
  //     console.error('store/CreateEntity:', error);
  //   }
  // },
};

const mutations = {
  _changeStep: (state, index) => {
    state.steps.find((el) => el.isActive).isActive = false;
    state.steps[index].isActive = true;
  },
  _completeStep: (state, payload) => (state.steps[payload.index].completed = payload.status),
  _setPrimaryData: (state, payload) => (state.primaryData = payload),
  _setTradePoints: (state, payload) => (state.tradePoints = payload),
  _setCategories: (state, payload) => (state.categories = payload),
  _addCategory: (state, payload) => (state.categories = [...state.categories, payload]),
  _removeTradePoint: (state, id) => state.tradePoints = (state.tradePoints.filter((tradePoint) => tradePoint.id !== id)),
  _removeCategory: (state, index) => state.categories.splice(index, 1),
  _clearPrimaryData: (state) => (state.primaryData = {}),
  _clearTradePoints: (state) => (state.tradePoints = []),
  _clearCategories: (state) => (state.categories = []),
  _clearSteps: (state) => (state.steps.map((step) => step.completed = false)),
  _updateTradePoints: (state, payload) => (state.tradePoints = [...state.tradePoints, ...payload]),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
