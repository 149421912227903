import { companyAPI } from '@/core/api/company';

const state = {
  reportsConfig: null,
};

const getters = {
  reportsConfig: (state) => state.reportsConfig,
};

const actions = {
  getReportsConfig: async ({ commit }) => {
    try {
      const config = await companyAPI.getReportsConfig();
      
      commit('_setReportsConfig', config);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  _setReportsConfig: (state, payload) => {
    state.reportsConfig = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
