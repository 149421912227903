<template>
  <div v-if="menu">
    <DefaultTemplate ref="DefaultTemplate" :display-mode="device">
      <template v-if="showSideBar" #sidebar>
        <Sidebar
          ref="sidebar"
          :menu="menu"
          :logo="isXmasEnabled ? 'logo' : 'logo'"
          :display-mode="device"
          :profile="profile"
          @toggleMenu="toggleMenu"
          @openMenu="openMenu"
        >
          <template #profile>
            <Profile
              v-if="profile"
              :avatar-src="profile.avatar_link"
              :text="profile.first_name"
              :subtext="profile.phone"
              :display-mode="device"
              @openMenu="openMenu"
            />
          </template>
        </Sidebar>
      </template>
      <template #header>

        <div v-if="redirected" class="special-noty">
          <p class="special-noty__text">
            <strong>{{ $t('Вы находитесь в последней версии личного кабинета.') }}</strong>
            {{ $t('Перейти к старой версии?') }}
          </p>
          <a class="special-noty__link" href="https://bbo.briskly.online/?redirected">{{ $t('Перейти') }}</a>
        </div>

        <Header :display-mode="device" @menuClickHadnler="headerMenuClickHandler" />
      </template>
      <template #content>
        <Notifications v-if="$route.name !== 'carts$carts'" />
        <NotificationsOnlineCarts v-else />

        <router-view class="router-view" />

        <div class="helper-icons" :class="isTablet ? 'tablet' : ''">
          <div>
            <Icon name="icon_help" class="help-icon" @click="openFAQ" />
          </div>
          <div v-if="!revealChat && isSupportChatEnabled">
            <Icon name="icon_chat" class="chat-icon" @click="openSupportChat" />
            <div v-if="unreadMessagesCount" class="chat-icon__counter">{{ unreadMessagesCount }}</div>
          </div>
        </div>

        <SideModal
          v-if="isFAQDisplayed"
          :show-buttons="false"
          :title="$t('Частые вопросы')"
          class="chat-sidemodal"
          @onClose="isFAQDisplayed = false"
        >
          <FAQ />
        </SideModal>

        <SideModal
          v-if="revealChat"
          :show-buttons="false"
          :title="$t('Служба поддержки')"
          class="chat-sidemodal"
          @onClose="onCloseSupportChat"
        >
          <Chat :messages="groupedItems" @sendMessage="sendNewChatMessage" @refetch="fetchMessages" />
        </SideModal>
      </template>
      <template #footer>
        <Footer />
      </template>
    </DefaultTemplate>
  </div>
<!--  <Snowflakes v-if="isXmasEnabled && snowflakesEnabled" />-->
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Icon, SideModal, BaseInput } from '@brskl/ui-lib';
import DefaultTemplate from '@/core/components/organism/DefaultTemplate/DefaultTemplate.vue';
import Notifications from '@/core/components/organism/Notifications/Notifications';
import NotificationsOnlineCarts from '@/core/components/organism/OnlineCartNotifications/Notifications.vue';

import Sidebar from '@/core/components/organism/Sidebar/Sidebar.vue';
import Profile from '@/core/components/organism/Profile/Profile.vue';
import Header from '@/core/components/layouts/Header/Header.vue';
import Footer from '@/core/components/layouts/Footer/Footer.vue';

import Chat from '@/core/components/Chat/Chat.vue';
import FAQ from '@/core/components/FAQ/FAQ.vue';

import { companyAPI } from '@/core/api/company.js';
import { uniqBy } from 'lodash';
import { format, compareAsc } from 'date-fns';
import { DatePicker } from 'v-calendar';
import { NoticeEnumTypes } from '@/core/data/Notice-enum';

import Snowflakes from '@/core/views/Snowflakes';
import {getCookie, setCookie} from "@/core/utils/cookie";

const isCookieSet = () => {
  try {
    return document.cookie
      .split(';')
      .map((i) => i.trim())
      .includes('redirected=1');
  } catch (e) {
    console.error(e);
    return false;
  }
};

export default {
  components: {
    Sidebar,
    Header,
    Footer,
    Profile,
    DefaultTemplate,
    Notifications,
    NotificationsOnlineCarts,
    Icon,
    Chat,
    FAQ,
    SideModal,
    BaseInput,
    Snowflakes,
    DatePicker,
  },

  data() {
    return {
      redirected: false,
      device: 'mobile',
      revealChat: false,
      messages: {},
      isFAQDisplayed: false,
    };
  },

  computed: {
    ...mapGetters({
      deviceIs: 'core$browser/deviceIs',
      menu: 'core$menu/menu',
      profile: 'shared$profile/profile',
      isReady: 'core$main/isReady',
      isTv: 'core$main/isTv',
      isSupportChatEnabled: 'core$main/isSupportChatEnabled',
      isXmasEnabled: 'core$main/isXmasEnabled',
      page_size: 'core$employeePreferences/pageSize',
      snowflakesEnabled: 'core$employeePreferences/isXmasEnabled',
      isMobileTableFooterOpened: 'mobileFooterStore$mobileFooterStore/isFooterOpened',
      shouldOpenChat: 'core$notifications/shouldOpenChat',
    }),

    isTablet() {
      return this.deviceIs === 'tablet';
    },

    isMapOpened() {
      return this.$route.name === 'tradepoints$list/map';
    },

    showSideBar() {
      return this.isReady;
    },

    unreadMessagesCount() {
      return Object.entries(this.messages).filter(
        ([, message]) => message.sender.alias === 'administrator' && message.is_read === false,
      ).length;
    },

    groupedItems() {
      const formatDate = (date) => format(new Date(date), 'yyyy-MM-dd');

      const dates = uniqBy(
        Object.entries(this.messages)
          .map(([, item]) => item.created_at.split(' ').join('T'))
          .sort((d1, d2) => compareAsc(new Date(d1), new Date(d2)))
          .map((item) => formatDate(item)),
      );

      const store = {};

      dates.forEach((date) => (store[date] = []));

      Object.entries(this.messages).forEach(([, item]) => {
        const key = formatDate(item.created_at.split(' ').join('T'));
        store[key].push(item);
      });

      const result = [];

      for (const [date, items] of Object.entries(store)) {
        result.push({ date, items: this.sortMessages(items) });
      }

      return result;
    },
  },

  methods: {
    ...mapMutations({
      setWindowSize: 'core$browser/setWindowSize',
      setUserBrowser: 'core$browser/setUserBrowser',
    }),

    ...mapActions({
      globalInit: 'core$main/globalInit',
      setTvMode: 'core$main/setTvMode',
      setPageSize: 'core$employeePreferences/setPageSize',
      setEmployeePreferences: 'core$employeePreferences/setEmployeePreferences',
      add: 'core$notifications/add',
      setChat: 'core$notifications/setChat',
    }),

    sortMessages(messages) {
      return messages.sort((m1, m2) => compareAsc(new Date(m1.created_at), new Date(m2.created_at)));
      // return messages.sort((m1, m2) => (m1.id > m2.id ? 1 : -1));
    },

    openMenu() {
      switch (this.device) {
        case 'desktop-tablet':
          return (this.device = 'desktop');
        default:
      }
    },

    toggleMenu() {
      switch (this.device) {
        case 'desktop':
          return (this.device = 'desktop-tablet');
        case 'desktop-tablet':
          return (this.device = 'desktop');
        case 'tablet':
        case 'mobile':
          return this.headerMenuClickHandler();
        default:
      }
    },

    headerMenuClickHandler() {
      this.$refs.sidebar.toggleForceCollapse();
    },

    openFAQ() {
      this.isFAQDisplayed = true;
    },

    async openSupportChat() {
      this.revealChat = true;

      const ids = Object.entries(this.messages)
        .filter(([, message]) => message.sender.alias === 'administrator' && message.is_read === false)
        .map(([, el]) => el.id);
      if (ids.length) {
        await companyAPI.setAsRead({ ids });
      }
    },

    onCloseSupportChat() {
      this.revealChat = false;
    },

    async handleChatMessage(data) {
      this.messages[data.chat_message.id] = data.chat_message;
      console.log(data, !this.revealChat && data.chat_message.is_read === false && data.chat_message.sender.alias === 'admin');

      if (this.revealChat && data.chat_message.is_read === false) {
        await companyAPI.setAsRead({ id: data.chat_message.id });
      } else if (!this.revealChat && data.chat_message.is_read === false && data.chat_message.sender.alias === 'administrator') {
        this.add({
          message: data.chat_message.text,
          type: 'chat-message',
          source: 'handleChatMessage',
          title: this.$t('Новое сообщение'),
        });
      }
    },

    async fetchMessages() {
      try {
        const payload = {
          limit: 9999,
          page: 1,
        };

        let { items } = await companyAPI.getMessages(payload);
        items = items.map((i) => ({ ...i, created_at: i.created_at.split(' ').join('T') }));

        items.forEach(async (el) => {
          await this.handleChatMessage({ chat_message: el });
        });
      } catch (error) {
        console.error(error);
      }
    },

    async sendNewChatMessage(text) {
      if (text.trim() === '') return;
      const response = await companyAPI.sendMessage({ text });

      console.log(response);
    },
  },
  watch: {
    deviceIs(device) {
      this.device = device;
    },
    shouldOpenChat(newVal) {
      console.log(newVal);
      if (newVal === true) {
        this.setChat(false);
        this.openSupportChat();
      }
    },
  },

  mounted() {
    this.setUserBrowser();
    window.addEventListener('resize', this.setWindowSize);
    this.setWindowSize();

    bus.on('core$chat/newChatMessage', this.handleChatMessage);
    this.fetchMessages();
    this.$watch(
      () => this.$route,
      (value) => {
        const excludedRoutes = ['auth', 'auth2', 'logout'];
        if (this.$route.query.isTV === 'true') {
          this.setTvMode(true);
          console.log('success');
          excludedRoutes.push('carts$carts');
        } else {
          this.setTvMode(false);
        }
        const catched = excludedRoutes.find((route) => route === value.name);

        this.$refs.DefaultTemplate.toggleLayouts(catched);
      },
      { immediate: true },
    );
  },
  async created() {
    try {
      const isRedirected = location.search.indexOf('?redirected') !== -1 || isCookieSet();
      if (isRedirected) {
        this.redirected = true;
        if (!isCookieSet()) {
          document.cookie = `redirected=1; max-age=${60 * 60 * 24 * 7}`;
        }
      } else {
        document.cookie = 'redirected=0; max-age=0';
      }
      const { preferences } = await companyAPI.getEmployeePreferences();
      await this.setEmployeePreferences({ key: 'page_size', preferences: preferences.page_size });
    } catch (error) {
      console.log(error);
    }
  },
  unmounted() {
    bus.off('core$chat/newChatMessage', this.handleChatMessage);
  },
};
</script>

<style lang="scss" scoped src="./Mainbubp.scss"></style>
