<template>
  <div class="notification-item">
    <div class="item__header">
      <span class="item__header-title">{{ title }}</span>
      <Icon name="icon_cross" @click="onClose" class="item__header-close" />
    </div>

    <div class="item__body u-mt--1 u--ml--3">{{ message }}</div>
  </div>
</template>

<script lang="ts">
import { Button, Icon } from '@brskl/ui-lib';
import { NoticeEnumTypes } from '@/core/data/Notice-enum';
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['close'],

  components: {
    //@ts-ignore
    Icon,
    //@ts-ignore
    Button,
  },
  //@ts-ignore
  computed: {
    type() {
      return this.item?.type || NoticeEnumTypes.default;
    },

    title() {
      return this.item?.title || '';
    },

    message() {
      if (this.type === 'error') {
        return !!this.item.message.error
          ? this.$t(this.item?.message.error.message)
          : this.$t('Что то пошло не так, попробуйте перезагрузить страницу') || '';
      } else {
        return this.item?.message || '';
      }
    },
  },

  props: {
    item: {
      type: Object,
    },
  },

  methods: {
    onClose() {
      this.$emit('close', this.item.id);
    },
    autoClose() {
      setTimeout(this.onClose, 7000);
    },
  },

  mounted() {
    this.autoClose();
  },
});
</script>

<style src="./RegularNotification_1.scss" lang="scss" scoped></style>
