
import { defineComponent } from 'vue';
import { Icon, BaseInput } from '@brskl/ui-lib';
import { format } from 'date-fns';
import Chip from '@/apps/reports/component/Chip.vue';

export default defineComponent({
  props: {
    messages: {
      type: Object,
    },
  },

  emits: ['sendMessage'],
  //@ts-ignore
  components: { Icon, BaseInput, Chip },

  data() {
    return {
      txt: 'txt',
      date: '20-04-2021',
      person: 'meme memev',
      newChatMessage: '',

      chat_message: {
        chat: {
          administrator_id: 48,
          company_id: 0,
          customer: null,
          employee: null,
          funnel: null,
          id: -1,
          last_comment: null,
          last_message: null,
          name: '',
          status: 1,
        },
        chat_id: 2633,
        created_at: this.formatDateToTime(new Date().toISOString()),
        files: [],
        id: -1,
        is_read: false,
        order: [],
        order_id: 0,
        reply_to_id: 0,
        reply_to_message: [],
        sender: {
          alias: 'administrator',
          id: 48,
          name: 'Briskly',
        },
        text: this.$t('support_service_greeting'),
        type: 0,
        scrollTimeout: null,
      },
    };
  },

  methods: {
    formatDateToTime(date: string) {
      const _date = date.split(' ').join('T');
      return format(new Date(_date), 'HH:mm');
    },

    formatChipToDayMonth(date) {
      const _date = date.split(' ').join('T');
      const month = 'MONTH_' + format(new Date(_date), 'MM');
      const day = format(new Date(_date), 'dd');
      return day + ` ${this.$t(month)}`;
    },

    onSendMessage() {
      this.$emit('sendMessage', this.newChatMessage);
      this.newChatMessage = '';
    },

    onKeydownSend(ev) {
      if (ev.shiftKey) return;

      ev.preventDefault();
      this.onSendMessage();
    },

    scrollToBottom(smooth = true) {
      this.$nextTick(() => {
        this.scrollTimeout = setTimeout(() => {
          this.$refs.chat.scrollTo({
            top: this.$refs.chat.scrollHeight,
            behavior: smooth ? 'smooth' : undefined,
          });
        }, 0);
      });
    },
  },

  watch: {
    messages(newMessage, oldMessage) {
      if (!!newMessage) {
        this.scrollToBottom();
      }
    },
  },

  mounted() {
    this.scrollToBottom();
  },

  unmounted() {
    clearTimeout(this.scrollTimeout);
  },
});
