// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('Эквайринг'),
    route: { name: 'shops$list' },
  },
];

export default [
  {
    path: '/shops',
    name: 'shops$list',
    component: () => import('@/apps/acquiring/views/List/List.vue'),
    meta: {
      breadCrumb: [breadCrumb[0]],
    },
  },
];
