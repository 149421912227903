<template>
  <div :class="['profile-container', `d-${displayMode}`]">
    <div class="profile-item">
      <Avatar :src="avatarSrc" class="profile-item-avatar" @click="openMenu()">
        {{ text && text[0] }}
      </Avatar>
      <router-link :to="{ name: 'profile$profile' }" class="profile-item-text">
        <div class="u-color--txtFocus"> {{ text }} </div>
        <div> {{ subtext }} </div>
      </router-link>
      <a href="/logout" class="profile-item-icon">
        <Icon name="icon_logout" />
      </a>
    </div>
  </div>
</template>

<script>
import { Avatar, Icon } from '@brskl/ui-lib';

export default {
  components: { Avatar, Icon },
  emits: ['openMenu'],
  props: {
    avatarSrc: String,
    text: String,
    subtext: String,
    displayMode: String,
  },
  methods: {
    openMenu() {
      this.$emit('openMenu');
    },
  },
};
</script>

<style lang="scss" scoped src="./Profile.scss"></style>
