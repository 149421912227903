import NotFound from '@/core/views/NotFound.vue';

import main from '@/apps/main/routes';
import discounts from '@/apps/discounts/routes';
import documents from '@/apps/documents/routes';
import devices from '@/apps/devices/routes';
import marketplace from '@/apps/marketplace/routes';
import employees from '@/apps/employees/routes';
import entities from '@/apps/entities/routes';
import items from '@/apps/items/routes';
import mailings from '@/apps/mailings/routes';
import orders from '@/apps/orders/routes';
import reports from '@/apps/reports/routes';
import tradePoints from '@/apps/trade-points/routes';
import catalogs from '@/apps/catalogs-categories/routes';
import profile from '@/apps/profile/routes';
import writeoffs from '@/apps/write-offs/routes';
import supply from '@/apps/supply/routes';
import tranfer from '@/apps/transfer/routes';
import terminals from '@/apps/terminals/routes';
import registration from '@/apps/registration/routes';
import Index from '@/core/views/Index.vue';
import Main from '@/core/views/Main.vue';
import remains from '@/apps/remains/routes';
import storageReports from '@/apps/storage-reports/routes';
import acquiring from '@/apps/acquiring/routes';
import operations from '@/apps/operations/routes';
import bills from '@/apps/bills/routes';
import refunds from '@/apps/refunds/routes';
import carts from '@/apps/carts/routes';
import events from '@/apps/events/routes';
import equipment from '@/apps/equipment/routes';
import serviceDevices from '@/apps/service_center/devices/routes';
import serviceContractors from '@/apps/service_center/contractors/routes';
import serviceDocuments from '@/apps/service_center/documents/routes';
import serviceDiagnostics from '@/apps/service_center/diagnostics/routes';
import help from '@/apps/help/routes';
import terminalPromo from '@/apps/terminal-promo/routes';
import menus from '@/apps/menus/routes';

import ChatModule from '@/core/components/Chat/Chat.vue';

export default [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/index',
    component: Index,
    children: [
      {
        path: '',
        component: Main,
        children: [
          ...main,
          ...registration,
          ...discounts,
          ...documents,
          ...employees,
          ...entities,
          ...items,
          ...mailings,
          ...orders,
          ...reports,
          ...tradePoints,
          ...catalogs,
          ...profile,
          ...writeoffs,
          ...supply,
          ...tranfer,
          ...terminals,
          ...devices,
          ...remains,
          ...storageReports,
          ...acquiring,
          ...operations,
          ...bills,
          ...refunds,
          ...carts,
          ...events,
          ...equipment,
          ...serviceDevices,
          ...serviceContractors,
          ...serviceDocuments,
          ...serviceDiagnostics,
          ...help,
          ...terminalPromo,
          ...marketplace,
          ...menus
        ],
      },
      {
        path: '/chat-module', component: ChatModule, props: {detachedMode: true}, beforeEnter: (to, from, next) => {
          next()
        }
      },
      { path: '/:pathMatch(.*)', component: NotFound },
    ],
  },
];

