export const lv = {
briskly_requisites: "",
main_data: "",
unit_796: "",
unit_657: "",
unit_111: "",
unit_112: "",
unit_163: "",
unit_625: "",
unit_704: "",
unit_715: "",
unit_166: "",
unit_736: "",
unit_778: "",
documents_tabs_docs: "",
documents_tabs_acts: "",
documents_tabs_bills: "",
documents_tabs_payment: "",
documents_requisites: "",
documents_type: "",
documents_added: "",
documents_is_signed: "",
documents_document_fallback_name: "",
documents_document_fallback_type: "",
documents_document_fallback_id: "",
documents_document_fallback_requisites: "",
Invoice: "",
UpdStatus1: "",
ActForTheReportingPeriod1: "",
ActForTheReportingPeriod2: "",
OrdersReport1: "",
BYN: "",
USD: "",
EUR: "",
KZT: "",
CAD: "",
CNY: "",
RUB: "",
TRY: "",
UAH: "",
GBP: "",
CHF: "",
BYN_CODE: "",
USD_CODE: "",
EUR_CODE: "",
KZT_CODE: "",
CAD_CODE: "",
CNY_CODE: "",
RUB_CODE: "",
TRY_CODE: "",
UAH_CODE: "",
GBP_CODE: "",
CHF_CODE: "",
datetimeCreatedAt: "",
customer: "",
status: "",
store: "",
refrigerator: "",
store_online: "",
cafe: "",
production_with_delivery: "",
filling_station: "",
cigarettes: "",
coffee_machine: "",
address: "",
phone: "",
active_status: "",
paid_status: "",
refund_status: "",
refund_awaiting_status: "",
refund_error_status: "",
refund_partial_status: "",
removed_status: "",
avg_amount: "",
income: "",
quantity: "",
total_amount: "",
extended_type: "",
extended_types_store: "",
extended_types_refrigerator: "",
extended_types_store_online: "",
extended_types_cafe: "",
extended_types_production_with_delivery: "",
extended_types_filling_station: "",
extended_types_cigarettes: "",
extended_types_coffee_machine: "",
extended_types_module: "",
store_count: "",
complete_registration: "",
your_company_country: "",
register: "",
your_company_inn: "",
your_company_number: "",
requisites_status_pending: "",
requisites_status_active: "",
requisites_status_uncompleted: "",
completed: "",
opened: "",
import_excel_file: "",
one_s: "",
iiko: "",
iiko_biz: "",
yml: "",
moy_sklad: "",
supermag: "",
mts_kassa: "",
store_house: "",
poster: "",
ya_badge: "",
article: "",
name: "",
price: "",
barcode: "",
unit_id: "",
volume: "",
category: "",
catalog_name: "",
Success_notification: "",
Error_notification: "",
temperature: "",
connection: "",
device_type_camera: "",
device_type_coffee_machine: "",
device_type_microwave_oven: "",
device_type_module: "",
device_type_terminal: "",
tax_system_envd: "",
tax_system_esn: "",
tax_system_general: "",
tax_system_patent: "",
tax_system_simplified_1: "",
tax_system_simplified_2: "",
role_admin: "",
role_checker: "",
role_courier: "",
roles: "",
error_1000: "",
error_1010: "",
error_1020: "",
error_1030: "",
error_1040: "",
error_1050: "",
};